import React from "react";
import Index from "../Index";
import * as XLSX from "xlsx";

const ImportExcelSheet = (props) => {
  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const workbook = XLSX.read(csvData, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = XLSX.utils.sheet_to_json(worksheet);
        if (json?.length > 0) {
          props?.getExcelData(json, file);
        }
      };

      reader.readAsBinaryString(file);
    }
  };
  return (
    <Index.Box className="btn_linear">
      <label
        className="linear-btn-main"
        htmlFor={props?.id}
        style={{ cursor: "pointer" }}
      >
        {props?.btnLabel}
      </label>
      <input
        type="file"
        id={props?.id}
        value=""
        name="account-file"
        className="custom_form_control"
        onChange={handleChange}
        hidden
        accept={props?.accept}
      />
    </Index.Box>
  );
};

export default ImportExcelSheet;
