import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateComponent = () => {
  const adminLogin = useSelector((state) => {
    return state.ERPAdminReducer.isAdminLogin;
  });

  return adminLogin ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateComponent;
