import React, { useState } from "react";
import Index from "../Index";
import MenuItemList from "./MenuItemList";
import { NavLink } from "react-router-dom";

function SideBar({ addClass }) {
  const location = Index.useLocation();

  const { type, permissions, priceType } = Index.useSelector((state) => {
    return state.ERPAdminReducer;
  });
  const masterMaintenanceMenuList = [
    {
      name: "Master Maintenance",
      type: "company",
      icon: "mastermaintenance",
      permission: permissions?.includes("master_maintenance_view"),
      child: [
        {
          name: "Account Master",
          link: "",
          type: "company",
          icon: "accountmaster",
          permission: permissions?.includes("account_master_main_view"),
          child: [
            {
              name: "Account Group Master",
              link: "/dashboard/account-group-master",
              type: "company",
              icon: "accountgroupmaster",
              permission: permissions?.includes("account_group_master_view"),
            },
            {
              name: "Account Master",
              link: "/dashboard/account-master",
              type: "company",
              icon: "inneraccountmaster",
              permission: permissions?.includes("account_master_view"),
            },
            {
              name: "Opening Balance",
              link: "/dashboard/opening-balance",
              type: "company",
              icon: "openingbalance",
              permission: permissions?.includes("opening_balance_view"),
            },
          ],
        },
        {
          name: "Product Master",
          link: "",
          type: "company",
          icon: "productmaster",
          permission: permissions?.includes("product_master_main_view"),
          child: [
            {
              name: "Product Group Master",
              link: "/dashboard/prouduct-group-master",
              type: "company",
              icon: "productgroupmaster",
              permission: permissions?.includes("product_group_master_view"),
            },
            {
              name: "Product Master",
              link: "/dashboard/product-master",
              type: "company",
              icon: "innerproductmaster",
              permission: permissions?.includes("product_master_view"),
            },
            {
              name: "Product Category Master",
              link: "/dashboard/product-category-master",
              type: "company",
              icon: "productcategorymaster",
              permission: permissions?.includes("product_category_master_view"),
            },
            {
              name: "Barcode Master",
              link: "/dashboard/barcode-list",
              type: "company",
              icon: "barcodemaster",
              permission: permissions?.includes("product_category_master_view"),
              // permission: 1,
            },
            {
              name: "HSN Master",
              link: "/dashboard/HSN-master",
              type: "company",
              icon: "hsnmaster",
              permission: permissions?.includes("hsn_master_view"),
            },
            {
              name: "Opening Stock",
              type: "company",
              icon: "openingstock",
              permission: permissions?.includes("opening_stock_view"),
              child: [
                {
                  name: "Finish Stock",
                  link: "/dashboard/finish-stock",
                  type: "company",
                  icon: "finishstock",
                  permission: permissions?.includes("finish_stock_view"),
                },
                {
                  name: "Other Stock",
                  link: "/dashboard/opening-stock",
                  type: "company",
                  icon: "otherstock",
                  permission: permissions?.includes("other_stock_view"),
                },
              ],
            },
            {
              name: "Price List",
              type: "company",
              icon: "pricelist",
              permission: permissions?.includes("item_wise_size_wise_view"),
              child: [
                {
                  name: "Price List Mark Down",
                  link: "/dashboard/item-size-price-list-down",
                  type: "company",
                  icon: "pricedown",
                  permission:
                    permissions?.includes("item_size_price_down_view") &&
                    priceType == "down",
                },
                {
                  name: "Price List Mark Up",
                  link: "/dashboard/item-size-price-list-up",
                  type: "company",
                  icon: "priceup",
                  permission:
                    permissions?.includes("item_size_price_up_view") &&
                    priceType == "up",
                },
              ],
            },
          ],
        },
        {
          name: "Tax Master",
          link: "",
          type: "company",
          icon: "taxmaster",
          permission: permissions?.includes("tax_master_main_view"),
          child: [
            {
              name: "Tax Master",
              link: "/dashboard/tax-master",
              type: "company",
              icon: "innertaxmaster",
              permission: permissions?.includes(
                "sales_expenses_tax_master_view"
              ),
            },
            {
              name: "Tax Category Master",
              link: "/dashboard/tax-category-master",
              type: "company",
              icon: "taxcategorymaster",
              permission: permissions?.includes("tax_category_master_view"),
            },
          ],
        },
      ],
    },
    {
      name: "Other Master",
      type: "company",
      icon: "othermaster",
      permission: permissions?.includes("master_view"),
      child: [
        {
          name: "Godown Master",
          link: "/dashboard/godwon-master",
          type: "company",
          icon: "godownmaster",
          permission: permissions?.includes("godown_master_view"),
        },
        {
          name: "Area Master",
          link: "/dashboard/area-master",
          type: "company",
          icon: "areamaster",
          permission: permissions?.includes("area_master_view"),
        },
        {
          name: "Narration Master",
          link: "/dashboard/narration-master",
          type: "company",
          icon: "narrationmaster",
          permission: permissions?.includes("narration_master_view"),
        },
        {
          name: "Department Master",
          link: "/dashboard/department-master",
          type: "company",
          icon: "departmentmaster",
          permission: permissions?.includes("department_master_view"),
        },
        {
          name: "Size Master",
          link: "/dashboard/size-master",
          type: "company",
          icon: "sizemaster",
          permission: permissions?.includes("size_master_view"),
        },
        {
          name: "Colour Master",
          link: "/dashboard/colour-master",
          type: "company",
          icon: "colormaster",
          permission: permissions?.includes("colour_master_view"),
        },
        {
          name: "Set Master",
          link: "/dashboard/set-master",
          type: "company",
          icon: "setmaster",
          permission: permissions?.includes("set_master_view"),
        },
        {
          name: "Process Master",
          link: "/dashboard/process-master",
          type: "company",
          icon: "processmaster",
          permission: permissions?.includes("process_master_view"),
        },
        {
          name: "Cost Group Master",
          link: "/dashboard/cost-group-master",
          type: "company",
          icon: "costgroupmaster",
          permission: permissions?.includes("cost_group_master_view"),
        },
        {
          name: "Fixed Assets",
          link: "/dashboard/fixed-assets",
          type: "company",
          icon: "fixedasset",
          permission: permissions?.includes("fixed_assets_master_view"),
        },
        {
          name: "Currency Master",
          link: "/dashboard/currency-master",
          type: "company",
          icon: "areamaster",
          permission: permissions?.includes("currency_master_view"),
        },
      ],
    },
    {
      name: "Daily Entry",
      type: "company",
      icon: "dailyentry",
      permission: permissions?.includes("daily_entry_view"),
      child: [
        {
          name: "Purchase",
          link: "",
          type: "company",
          icon: "purchaseions",
          permission: permissions?.includes("daily_entry_purchase_view"),
          child: [
            {
              name: "GRN Entry",
              link: "/dashboard/grn-list",
              type: "company",
              icon: "grnentry",
              permission: permissions?.includes("grn_entry_view"),
            },
            {
              name: "Purchase Entry",
              link: "/dashboard/purchase-entry-list",
              type: "company",
              icon: "purchaselist",
              permission: permissions?.includes("purchase_entry_view"),
            },
            {
              name: "Purchase Entry(Size Wise)",
              link: "/dashboard/purchase-entry-size-wise-list",
              type: "company",
              icon: "purchasentrysizewise",
              permission: permissions?.includes(
                "purchase_entry_size_wise_view"
              ),
            },
            {
              name: "Purchase Return",
              link: "/dashboard/purchase-return-list",
              type: "company",
              icon: "purchasereturn",
              permission: permissions?.includes("purchase_return_view"),
            },
          ],
        },
        {
          name: "Sales",
          link: "",
          type: "company",
          icon: "salesicons",
          permission: permissions?.includes("daily_entry_sales_view"),
          child: [
            {
              name: "Packing / Challan Entry",
              link: "/dashboard/packing-slip-list",
              type: "company",
              icon: "challanentry",
              permission: permissions?.includes("packing_challan_entry_view"),
            },
            {
              name: "Sales Entry",
              link: "/dashboard/sales-entry-list",
              type: "company",
              icon: "salesentry",
              permission: permissions?.includes("sales_entry_view"),
            },
            {
              name: "Sales Returns",
              link: "/dashboard/sales-return-list",
              type: "company",
              icon: "salesretrun",
              permission: permissions?.includes("sales_return_view"),
            },
            {
              name: "Other Sales Entry",
              link: "/dashboard/other-sales-entry-list",
              type: "company",
              icon: "othersalesentry",
              permission: permissions?.includes("other_sales_entry_view"),
            },
            {
              name: "LR Updation",
              link: "/dashboard/lr-update",
              type: "company",
              icon: "othersalesentry",
              // permission: permissions?.includes("lr_updation_view"),
              permission: true,
            },
          ],
        },
        {
          name: "Cash Bank Entry",
          link: "",
          type: "company",
          icon: "cashbankentry",
          permission: permissions?.includes("cash_back_entry_view"),
          child: [
            {
              name: "Cash Bank Entry",
              link: "/dashboard/cashbank-list",
              type: "company",
              icon: "othersalesentry",
              permission: 1,
            },
            {
              name: "Advance Adjustment",
              link: "/dashboard/advance-adjustment",
              type: "company",
              icon: "othersalesentry",
              permission: 1,
            },
            {
              name: "TDS Entry",
              link: "/dashboard/tds-entry-list",
              type: "company",
              icon: "othersalesentry",
              permission: 1,
            },
          ],
        },
      ],
    },
    {
      name: "Account Voucher Entry",
      type: "company",
      icon: "dailyentry",
      permission: permissions?.includes("account_voucher_entry_view"),
      child: [
        {
          name: "Sales",
          link: "/dashboard/sales-voucher-entry-list",
          type: "company",
          icon: "salesentry",
          permission: permissions?.includes("voucher_entry_sales_view"),
        },
        {
          name: "Purchase",
          link: "/dashboard/purchase-voucher-entry-list",
          type: "company",
          icon: "salesentry",
          permission: permissions?.includes("voucher_entry_purchase_view"),
        },
        {
          name: "Credit Note",
          link: "/dashboard/credit-note-entry-list",
          type: "company",
          icon: "salesentry",
          permission: permissions?.includes("voucher_entry_credit_note_view"),
        },
        {
          name: "Debit Note(Purchase)",
          link: "/dashboard/debit-note-entry-list",
          type: "company",
          icon: "salesentry",
          permission: permissions?.includes("voucher_entry_credit_note_view"),
        },
        {
          name: "Debit Note(Sales)",
          link: "/dashboard/debit-note-sales-list",
          type: "company",
          icon: "salesentry",
          permission: permissions?.includes("voucher_entry_credit_note_view"),
        },
      ],
    },
    {
      name: "Stock Transfer",
      type: "company",
      icon: "dailyentry",
      permission: 1,
      child: [
        {
          name: "Stock Transfer",
          link: "/dashboard/stock-transfer-list",
          type: "company",
          icon: "salesentry",
          permission: 1,
        },
        {
          name: "Surplus Wastage Entry",
          link: "/dashboard/surplus-wastage-list",
          type: "company",
          icon: "salesentry",
          permission: 1,
        },
      ],
    },
    {
      name: "Reports",
      link: "",
      type: "company",
      icon: "Reportsgray",
      permission: permissions?.includes("report_main_view"),
      child: [
        {
          name: "Purchase Report",
          link: "",
          type: "company",
          icon: "purchasereport",
          permission: permissions?.includes("purchase_report_main_view"),
          child: [
            {
              name: "GRN Report",
              link: "/dashboard/grn-report",
              type: "company",
              icon: "grnreports",
              permission: permissions?.includes("grn_report_view"),
            },
            {
              name: "Purchase Register",
              link: "/dashboard/purchase-entry-report",
              type: "company",
              icon: "purchaseregister",
              permission: permissions?.includes("purchase_entry_report_view"),
            },
            {
              name: "Purchase Return Register",
              link: "/dashboard/purchase-return-report",
              type: "company",
              icon: "purchaseretruns",
              permission: permissions?.includes("purchase_return_report_view"),
            },
          ],
        },
        {
          name: "Sales Report",
          link: "",
          type: "company",
          icon: "salesreports",
          permission: permissions?.includes("sales_report_main_view"),
          child: [
            {
              name: "Packing / Challan Reports",
              link: "/dashboard/packing-slip-report",
              type: "company",
              icon: "challanreport",
              permission: permissions?.includes("packing_challan_report_view"),
            },
            {
              name: "Sales Register",
              link: "/dashboard/sales-entry-report",
              type: "company",
              icon: "salesregiter",
              permission: permissions?.includes("sales_entry_report_view"),
            },
            {
              name: "Sales Returns Register",
              link: "/dashboard/sales-return-report",
              type: "company",
              icon: "salesregiterreturn",
              permission: permissions?.includes("sales_return_report_view"),
            },
          ],
        },
        {
          name: "Material Stock Report",
          link: "/dashboard/material-stock-report",
          type: "company",
          icon: "materialstock",
          permission: permissions?.includes("material_stock_report_view"),
        },
        {
          name: "Stock Report",
          link: "/dashboard/stock-report",
          type: "company",
          icon: "stockreport",
          permission: permissions?.includes("reports_view"),
        },
        {
          name: "Journal Voucher Report",
          link: "/dashboard/jounral-voucher-report",
          type: "company",
          icon: "stockreport",
          permission: 1,
        },
        {
          name: "Ledger Report",
          link: "/dashboard/ledger-report",
          type: "company",
          icon: "stockreport",
          permission: 1,
        },
        {
          name: "TDS Report",
          link: "/dashboard/tds-report",
          type: "company",
          icon: "materialstock",
          permission: 1,
        },
        {
          name: "Commission Report",
          link: "/dashboard/commission-report",
          type: "company",
          icon: "materialstock",
          permission: 1,
        },
        {
          name: "Account Report",
          link: "/dashboard/account-report",
          type: "company",
          icon: "materialstock",
          permission: 1,
        },
        {
          name: "Product Master Report",
          link: "/dashboard/product-master-report",
          type: "company",
          icon: "materialstock",
          permission: 1,
        },
        {
          name: "Cashbank Report",
          link: "",
          type: "company",
          icon: "salesreports",
          permission: 1,
          child: [
            {
              name: "Bank Book",
              link: "/dashboard/cashbank-book-report",
              type: "company",
              icon: "challanreport",
              permission: 1,
            },
            {
              name: "Cashbank Register",
              link: "/dashboard/cashbank-register-report",
              type: "company",
              icon: "salesregiter",
              permission: 1,
            },
          ],
        },
      ],
    },
    {
      name: "Order Processing",
      type: "company",
      icon: "orderprocessing",
      permission: permissions?.includes("order_processing_view"),
      child: [
        {
          name: "Purchase Order",
          link: "",
          type: "company",
          icon: "purchaseorder",
          permission: permissions?.includes("purchase_order_view"),
          child: [
            {
              name: "Purchase Order",
              link: "/dashboard/purchase-order",
              type: "company",
              icon: "innerpurchaseorder",
              permission: permissions?.includes("purchase_order_module_view"),
            },
            {
              name: "Purchase Order Report",
              link: "/dashboard/purchase-order-report",
              type: "company",
              icon: "purchaseorderreport",
              permission: permissions?.includes("purchase_order_report_view"),
            },
          ],
        },
        {
          name: "Sales Order",
          link: "",
          type: "company",
          icon: "salesorderport",
          permission: permissions?.includes("sales_order_view"),
          child: [
            {
              name: "Sales Order",
              link: "/dashboard/sales-order-list",
              type: "company",
              icon: "salesorder",
              permission: permissions?.includes("sales_order_module_view"),
            },
            {
              name: "Sales Order Report",
              link: "/dashboard/sales-order-report",
              type: "company",
              icon: "salesorderport",
              permission: permissions?.includes("sales_order_report_view"),
            },
          ],
        },
      ],
    },
    {
      name: "Manage Users",
      type: "company",
      icon: "manageuser",
      permission: permissions?.includes("manage_users_view"),
      child: [
        {
          name: "Role Master",
          link: "/dashboard/roles",
          type: "company",
          icon: "rolemaster",
          permission:
            type == "admin" || permissions?.includes("role_master_view"),
        },
        {
          name: "User Creation",
          link: "/dashboard/manage-users",
          type: "company",
          icon: "usercreation",
          permission: permissions?.includes("user_creation_view"),
        },
      ],
    },
    {
      name: "Master",
      type: "admin",
      permission: true,
      icon: "mastericon",
      child: [
        {
          name: "Country Master",
          link: "/dashboard/country-master",
          type: "admin",
          icon: "countrymaster",
          permission: true,
        },
        {
          name: "State Master",
          link: "/dashboard/state-master",
          type: "admin",
          icon: "statemaster",
          permission: true,
        },
        {
          name: "City Master",
          link: "/dashboard/city-master",
          type: "admin",
          icon: "citymaster",
          permission: true,
        },
        {
          name: "Unit Master",
          link: "/dashboard/unit-master",
          type: "admin",
          icon: "unitmaster",
          permission: true,
        },
        {
          name: "Zone Master",
          link: "/dashboard/zone-master",
          type: "admin",
          icon: "zonemaster",
          permission: true,
        },
        {
          name: "Year Master",
          link: "/dashboard/year-master",
          type: "admin",
          icon: "yearmaster",
          permission: true,
        },
      ],
    },
  ];

  const masterArray = [
    "/dashboard/unit-master",
    "/dashboard/area-master",
    "/dashboard/country-master",
    "/dashboard/state-master",
    "/dashboard/city-master",
    "/dashboard/zone-master",
    "/dashboard/godwon-master",
    "/dashboard/narration-master",
    "/dashboard/process-master",
    "/dashboard/size-master",
    "/dashboard/colour-master",
    "/dashboard/set-master",
    "/dashboard/HSN-master",
  ];

  const masterArray2 = [
    "/dashboard/tax-category-master",
    "/dashboard/product-category-master",
    "/dashboard/prouduct-group-master",
    "/dashboard/product-master",
    "/dashboard/finish-stock",
    "/dashboard/opening-stock",
  ];
  return (
    <>
      <Index.Box className="sidebar_main">
        <Index.List className="ul_sidebar_list">
          {(type === "admin" ||
            (type != "admin" && permissions?.includes("dashboard_view"))) && (
              <Index.ListItem
                className={
                  location.pathname == "/dashboard/home"
                    ? "li_sidebar_list active"
                    : "li_sidebar_list"
                }
              >
                <NavLink
                  activeClassName="active"
                  className="redirect_sidebar_page"
                  to={"home"}
                >
                  <Index.Box className="sidebar_btn_list">
                    <img
                      src={Index.Svg.dashboardicon}
                      className="sidebar-icons"
                    ></img>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="sidebar_title"
                    >
                      Dashboard
                    </Index.Typography>
                  </Index.Box>
                </NavLink>
              </Index.ListItem>
            )}
          {type === "admin" && (
            <Index.ListItem
              className={
                location.pathname == "/dashboard/client-list"
                  ? "li_sidebar_list active"
                  : "li_sidebar_list"
              }
            >
              <Index.Link className="redirect_sidebar_page" to={"client-list"}>
                <Index.Box className="sidebar_btn_list">
                  <img
                    src={Index.Svg.clientmaster}
                    className="sidebar-icons"
                  ></img>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="sidebar_title"
                  >
                    Client Master
                  </Index.Typography>
                </Index.Box>
              </Index.Link>
            </Index.ListItem>
          )}

          <MenuItemList array={masterMaintenanceMenuList} type={type} />

          {type != "admin" && permissions?.includes("finish_entry_view") && (
            <Index.ListItem className="li_sidebar_list">
              <NavLink
                activeClassName="active"
                className="redirect_sidebar_page"
                to={"/dashboard/finish-entry-list"}
              >
                <Index.Box className="sidebar_btn_list">
                  <img
                    src={Index.Svg.finishentery}
                    className="sidebar-icons"
                  ></img>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="sidebar_title"
                  >
                    Finish Entry
                  </Index.Typography>
                </Index.Box>
              </NavLink>
            </Index.ListItem>
          )}

          {/* {type != "admin" && permissions?.includes("manage_users_view") && (
              <Index.ListItem className="li_sidebar_list">
                <Index.Link className="redirect_sidebar_page">
                  <Index.Box className="sidebar_btn_list">
                    <Index.Box className="svg_img_main">
                      <img
                        src={Index.Svg.Dailyentrygray}
                        className="side_logo"
                      ></img>
                      <img
                        src={Index.Svg.Dailyentrywhite}
                        className="side_logo_white"
                      ></img>
                    </Index.Box>
                    <Index.Box className="sidebar_text_details">
                      <Index.Link to="/dashboard/manage-users">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="sidebar_title"
                        >
                          Manage Users
                        </Index.Typography>
                      </Index.Link>
                    </Index.Box>
                  </Index.Box>
                </Index.Link>
              </Index.ListItem>
            )} */}

          {/* {type != "admin" && permissions?.includes("reports_view") && (
              <Index.ListItem className="li_sidebar_list">
                <Index.Link
                  to="/dashboard/stock-report"
                  className="redirect_sidebar_page"
                >
                  <Index.Box className="sidebar_btn_list">
                    <Index.Box className="svg_img_main">
                      <img
                        src={Index.Svg.Reportsgray}
                        className="side_logo"
                      ></img>
                      <img
                        src={Index.Svg.Reportswhite}
                        className="side_logo_white"
                      ></img>
                    </Index.Box>
                    <Index.Box className="sidebar_text_details">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="sidebar_title"
                      >
                        Reports
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Link>
              </Index.ListItem>
            )} */}

          {/* {(type === "company" && permissions?.includes("order_processing_view")) && (
            <Index.ListItem className={
              location.pathname == "/dashboard/sales-order-list"
                ? "li_sidebar_list active"
                : "li_sidebar_list"
            }>
              <Index.Link className="redirect_sidebar_page">
                <Index.Box className="sidebar_btn_list">
                  <Index.Box className="svg_img_main">
                    <img src={Index.Svg.ordergray} className="side_logo"></img>
                    <img
                      src={Index.Svg.orderwhite}
                      className="side_logo_white"
                    ></img>
                  </Index.Box>
                  <Index.Box className="sidebar_text_details">
                  <Index.Link to="/dashboard/sales-order-list">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="sidebar_title"
                    >
                      Order Processing
                    </Index.Typography>
                    </Index.Link>
                  </Index.Box>
                </Index.Box>
              </Index.Link>
            </Index.ListItem>)} */}

          {/* {type === "company" && (
            <Index.ListItem className={
              location.pathname == "/dashboard/packing-slip-list"
                ? "li_sidebar_list active"
                : "li_sidebar_list"
            }>
              <Index.Link className="redirect_sidebar_page">
                <Index.Box className="sidebar_btn_list">
                  <Index.Box className="svg_img_main">
                    <img src={Index.Svg.ordergray} className="side_logo"></img>
                    <img
                      src={Index.Svg.orderwhite}
                      className="side_logo_white"
                    ></img>
                  </Index.Box>
                  <Index.Box className="sidebar_text_details">
                  <Index.Link to="/dashboard/packing-slip-list">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="sidebar_title"
                    >
                      Packing Slip
                    </Index.Typography>
                    </Index.Link>
                  </Index.Box>
                </Index.Box>
              </Index.Link>
            </Index.ListItem>)} */}

          {type != "admin" && permissions?.includes("utility_view") && (
            <Index.ListItem className="li_sidebar_list">
              <Index.Link className="redirect_sidebar_page">
                <Index.Box className="sidebar_btn_list">
                  <img
                    src={Index.Svg.utilityicon}
                    className="sidebar-icons"
                  ></img>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="sidebar_title"
                  >
                    Utility
                  </Index.Typography>
                </Index.Box>
              </Index.Link>
            </Index.ListItem>
          )}
        </Index.List>
      </Index.Box>
    </>
  );
}

export default SideBar;
