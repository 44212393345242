import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import Switch from "@mui/material/Switch";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getCompanySetupDataApiHandler,
  updateCompanySetupApiHandler,
} from "../../../../services/AdminIndex";

const IOSSwitch = Index.styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#114A65",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function CompanySetup() {
  const { state } = useLocation();
  const companyId = state?.companyId;

  const dispatch = useDispatch();

  const { loading, token } = Index.useSelector((state) => {
    return state.ERPAdminReducer;
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = Index.useForm();

  const [companyAccess, setCompanyAccess] = useState({
    modules: {
      purchase: false,
      sales: false,
      accounting: false,
      process: false,
    },
    utility: {
      telly: false,
      whatsappIntegration: false,
      ewaybill: false,
      einvoice: false,
    },
  });

  useEffect(() => {
    getCompanySetupData();
  }, [companyId]);

  const getCompanySetupData = () => {
    try {
      getCompanySetupDataApiHandler(token, companyId).then((data) => {
        if (data.status === 201 || data.status === 200) {
          if (data.data?.[0]?.id) {
            setCompanyAccess(JSON.parse(data.data?.[0]?.access));
            setValue('ewayBillUsername',data.data?.[0]?.ewayBillUsername);
            setValue('ewayBillPassword',data.data?.[0]?.ewayBillPassword);
            setValue('einvoiceBillUsername',data.data?.[0]?.einvoiceBillUsername);
            setValue('einvoiceBillPassword',data.data?.[0]?.einvoiceBillPassword);
          }
        } else {
          dispatch({ type: "ADMIN_ERROR_HANDLER", payload: data });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateAccess = (propertyPath, value) => {
    setCompanyAccess((prevState) => {
      const properties = propertyPath.split(".");
      const newState = { ...prevState };
      let target = newState;
      for (let i = 0; i < properties.length - 1; i++) {
        target = target[properties[i]];
      }
      target[properties[properties.length - 1]] = value;
      return newState;
    });
  };

  const submit = (data) => {
    data.access = JSON.stringify(companyAccess);
    data.companyId = companyId;
    try {
      updateCompanySetupApiHandler(token, data).then((response) => {
        if (response.status === 201 || response.status === 200) {
          toast.success("Setting updated successfully");
        } else {
          dispatch({ type: "ADMIN_ERROR_HANDLER", payload: response });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Index.Box className="main_content">
        <Index.Box className="title_top_fix">
          <Index.Box className="flex_justify">
              <Index.Typography variant="h4" component="h4">
                Company Setup
              </Index.Typography>
          </Index.Box>
        </Index.Box>

        <Index.Box className="web_main_content">
          <Index.Box className="over_text_scroll">
            <form className="form-content" onSubmit={handleSubmit(submit)}>
              <Index.Box className="company-col-flex">
                <Index.Box className="common-card">
                  <Index.Box className="compnay-setup-content-main">
                    <Index.Typography className="company-setup-titles">
                      Company Modules
                    </Index.Typography>
                    <Index.Box className="company-setup-flex">
                      <Index.Typography className="company-switch-lable">
                        Purchase
                      </Index.Typography>
                      <Index.Box className="company-setup-switch-main">
                        <Index.FormControlLabel
                          onChange={() =>
                            updateAccess(
                              "modules.purchase",
                              !companyAccess?.modules?.purchase
                            )
                          }
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={companyAccess?.modules?.purchase}
                            />
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="company-setup-flex">
                      <Index.Typography className="company-switch-lable">
                        Sales
                      </Index.Typography>
                      <Index.Box className="company-setup-switch-main">
                        <Index.FormControlLabel
                          onChange={() =>
                            updateAccess(
                              "modules.sales",
                              !companyAccess?.modules?.sales
                            )
                          }
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={companyAccess?.modules?.sales}
                            />
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="company-setup-flex">
                      <Index.Typography className="company-switch-lable">
                        Accounting
                      </Index.Typography>
                      <Index.Box className="company-setup-switch-main">
                        <Index.FormControlLabel
                          onChange={() =>
                            updateAccess(
                              "modules.accounting",
                              !companyAccess?.modules?.accounting
                            )
                          }
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={companyAccess?.modules?.accounting}
                            />
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="company-setup-flex">
                      <Index.Typography className="company-switch-lable">
                        Process
                      </Index.Typography>
                      <Index.Box className="company-setup-switch-main">
                        <Index.FormControlLabel
                          onChange={() =>
                            updateAccess(
                              "modules.process",
                              !companyAccess?.modules?.process
                            )
                          }
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={companyAccess?.modules?.process}
                            />
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="compnay-setup-content-main">
                    <Index.Typography className="company-setup-titles">
                      Utility
                    </Index.Typography>
                    <Index.Box className="company-setup-flex">
                      <Index.Typography className="company-switch-lable">
                        Telly
                      </Index.Typography>
                      <Index.Box className="company-setup-switch-main">
                        <Index.FormControlLabel
                          onChange={() =>
                            updateAccess(
                              "utility.telly",
                              !companyAccess?.utility?.telly
                            )
                          }
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={companyAccess?.utility?.telly}
                            />
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="company-setup-flex">
                      <Index.Typography className="company-switch-lable">
                        Whatsapp Integration
                      </Index.Typography>
                      <Index.Box className="company-setup-switch-main">
                        <Index.FormControlLabel
                          onChange={() =>
                            updateAccess(
                              "utility.whatsappIntegration",
                              !companyAccess?.utility?.whatsappIntegration
                            )
                          }
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={
                                companyAccess?.utility?.whatsappIntegration
                              }
                            />
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="company-setup-flex">
                      <Index.Typography className="company-switch-lable">
                        Eway Bill
                      </Index.Typography>
                      <Index.Box className="company-setup-switch-main">
                        <Index.FormControlLabel
                          onChange={() =>
                            updateAccess(
                              "utility.ewaybill",
                              !companyAccess?.utility?.ewaybill
                            )
                          }
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={companyAccess?.utility?.ewaybill}
                            />
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    {companyAccess?.utility?.ewaybill && (
                      <>
                        <Index.Box
                          sx={{ width: 1 }}
                          className="grid-main"
                        >
                          <Index.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 1, md: 2, lg: 2 }}  >
                            <Index.Box
                              gridColumn={{
                                xs: "span 6",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label_control">
                                    Username
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="custom_form_control"
                                    value={watch("ewayBillUsername")}
                                    {...register("ewayBillUsername", {
                                      required: companyAccess?.utility?.ewaybill
                                        ? true
                                        : false,
                                    })}
                                    onChange={(e) => {
                                      setValue("ewayBillUsername", e.target.value);
                                    }}
                                    error={Boolean(errors.ewayBillUsername)}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 6",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label_control">
                                    Password
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="custom_form_control"
                                    value={watch("ewayBillPassword")}
                                    {...register("ewayBillPassword", {
                                      required: companyAccess?.utility?.ewaybill
                                        ? true
                                        : false,
                                    })}
                                    onChange={(e) => {
                                      setValue("ewayBillPassword", e.target.value);
                                    }}
                                    error={Boolean(errors.ewayBillPassword)}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    )}
                    <Index.Box className="company-setup-flex">
                      <Index.Typography className="company-switch-lable">
                        E - Invoice
                      </Index.Typography>
                      <Index.Box className="company-setup-switch-main">
                        <Index.FormControlLabel
                          onChange={() =>
                            updateAccess(
                              "utility.einvoice",
                              !companyAccess?.utility?.einvoice
                            )
                          }
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={companyAccess?.utility?.einvoice}
                            />
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    {companyAccess?.utility?.einvoice && (
                      <>
                        <Index.Box
                          gridColumn={{
                            xs: "span 6",
                            sm: "span 4",
                            md: "span 2",
                            lg: "span 2",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="label_control">
                                Username
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="custom_form_control"
                                value={watch("einvoiceBillUsername")}
                                {...register("einvoiceBillUsername", {
                                  required: companyAccess?.utility?.einvoice
                                    ? true
                                    : false,
                                })}
                                onChange={(e) => {
                                  setValue("einvoiceBillUsername", e.target.value);
                                }}
                                error={Boolean(errors.einvoiceBillUsername)}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 6",
                            sm: "span 4",
                            md: "span 2",
                            lg: "span 2",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="label_control">
                                Password
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="custom_form_control"
                                value={watch("einvoiceBillPassword")}
                                {...register("einvoiceBillPassword", {
                                  required: companyAccess?.utility?.einvoice
                                    ? true
                                    : false,
                                })}
                                onChange={(e) => {
                                  setValue("einvoiceBillPassword", e.target.value);
                                }}
                                error={Boolean(errors.einvoiceBillPassword)}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="btn-flex-end">
                <Index.LinearButton
                  btnLabel="Save"
                  className="linear-btn-main savechange_btn"
                  type="submit"
                ></Index.LinearButton>
              </Index.Box>
            </form>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
