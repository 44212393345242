import React, { useState, useEffect, useRef } from "react";
import Index from "../../../../component/Index";
import ContainerIndex from "../../../Index";
import { styled } from "@mui/material/styles";
import { TextareaAutosize } from "@mui/base";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import BarcodeDropdown from "./BarcodeDropdown";

import {
  GetSizeMasterListAction,
  getSalesPrerequisite,
  getSetMasterWithSizeAction,
  getSalesUsersAction,
  createSalesOrderAction,
  // getSalesOrderAction,
} from "../../../../redux/admin/action";
import { Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSalesListApiHandler,
  getProductSetRateAmount,
  getSalesOrderNo,
  getSingleProductStockApiHandler,
} from "../../../../services/AdminIndex";
import LoadingSpinner from "../../../../component/LoadingSpinner";
import Spinner from "../../../../component/Spinner";

const Item = styled(Index.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const stylemodal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function SalesOrder() {
  const { id } = useParams();
  const navigate = useNavigate();
  const editId = id;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = Index.useForm();

  const productRef = useRef();
  const dispatch = useDispatch();
  const [productStock, setProductStock] = useState([]);
  const [pendingSales, setPendingSales] = useState([]);

  const [barcodeIndex, setBarcodeIndex] = useState("");
  const [open, setOpen] = useState(false);
  const [removedProductId, setRemovedProductId] = useState([]);
  const [editData, setEditData] = useState({});
  const [sundryDebitors, setSundryDebitors] = useState([]);
  const [sundryDebitorsPrincipal, setSundryDebitorsPrincipal] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [transportList, setTransportList] = useState([]);
  const [productWiseColorOptions, setProductWiseColorOptions] = useState([]);
  const [setsWiseSizeOptions, setSetsWiseSizeOptions] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [saveButtonLoader, setSaveButtonLoader] = useState(false);

  const [productRow, setProductRow] = useState([
    {
      id: Math.random(),
      productId: "",
      description: "",
      colorId: "",
      setId: "",
      sizeQuantity: [],
      stock: [],
      mrp: "",
      rate: "",
      priceId: 0,
      totalQuantity: "",
      remarks: "",
    },
  ]);

  const {
    loading,
    token,
    adminProfile,
    getSizeMaster,
    salesPrerequisiteData,
    setMasterWithSize,
    saleUsersData,
    selectedYearStart,
    selectedYearEnd,
  } = Index.useSelector((state) => {
    return state.ERPAdminReducer;
  });
  useEffect(() => {
    dispatch(GetSizeMasterListAction(token, 1));
    dispatch(getSalesPrerequisite(token));
    dispatch(getSetMasterWithSizeAction(token));
    dispatch(getSalesUsersAction(token));
  }, []);

  useEffect(() => {
    if (!editId) {
      // Set today date if selected year is current financial year or set start date of selected year
      let currentYear = dayjs().year();
      let selectedYear = dayjs(selectedYearStart).year();
      let dateToShow = dayjs();
      if (currentYear != selectedYear) {
        dateToShow = dayjs(selectedYearStart);
      }

      setValue("orderDate", dayjs(dateToShow));
      setValue("deliveryDate", dayjs(dateToShow));
      setValue("poDate", dayjs(dateToShow));
    }
  }, [selectedYearStart]);

  const getEditData = () => {
    dispatch({ type: "LOADER_START" });
    getSalesListApiHandler(token, 0, editId).then((data) => {
      dispatch({ type: "LOADER_START" });
      if (data.status === 201 || data.status === 200) {
        setEditData(data?.data?.[0]);
        dispatch({ type: "LOADER_END" });
      } else {
        dispatch({ type: "ADMIN_ERROR_HANDLER", payload: data });
      }
    });
  };

  // Call edit API
  useEffect(() => {
    getEditData();
  }, []);
  useEffect(() => {
    if (editId) {
      setValue("orderNo", editData?.orderNo);
      setValue("principalParty", editData?.principalPartyId);
      setValue("remarks", editData?.remarks);
      setValue("orderDate", dayjs(editData?.orderDate));
      setValue("party", editData?.partyId);
      setValue("deliveryDate", dayjs(editData?.deliveryDate));
      setValue("partyPONo", editData?.partyPoNo);
      setValue("poDate", dayjs(editData?.partyPoDate));
      setValue("agent", editData?.agentId);
      setValue("transport", editData?.transportId);
      setValue("deliveryAt", editData?.deliveryAtId);
      const productEditData = parseData(editData?.productData);
      productEditData?.map((row, index) => {
        let sizeDataArray = [];
        row?.sizeQuantity?.map((size) => {
          let sizeObject = {
            id: size?.id,
            sizeId: size?.sizeId,
            quantity: size?.qty,
          };
          sizeDataArray[size?.sizeId] = sizeObject;
        });
        const rowObject = {
          id: row?.id,
          productId: row?.productCode,
          description: row?.description,
          colorId: row?.colorId,
          setId: row?.setId,
          sizeQuantity: sizeDataArray,
          mrp: row?.mrp,
          rate: row?.rate,
          priceId: row?.priceId,
          previousPriceId: row?.priceId,
          totalQuantity: row?.qty,
          remarks: row?.remarks,
        };

        setProductRow((data) => {
          let arr = [...data];
          arr[index] = rowObject;
          return arr;
        });

        getSingleProductStock(row?.productCode, row?.colorId);
      });
    }
    // setProductRow(productEditData);
  }, [editData]);

  useEffect(() => {
    setSundryDebitorsPrincipal(saleUsersData?.sundryDebtorsPrincipal);

    setAgentList(saleUsersData?.broker);

    setTransportList(saleUsersData?.sundryCreditorTransport);

    setSundryDebitors(saleUsersData?.sundryDebtors);
  }, [saleUsersData]);
  const validateData = (arr) => {
    let error = {};
    //check if any row is empty
    arr?.map((row, mainIndex) => {
      if (!row?.productId) {
        error["productId" + mainIndex] = "Product is require";
      }
      if (!row?.colorId) {
        error["colorId" + mainIndex] = "Color is require";
      }
      if (!row?.setId) {
        error["setId" + mainIndex] = "Set is require";
      }
      if (!row?.mrp) {
        error["mrp" + mainIndex] = "Mrp is require";
      }
      if (!row?.rate) {
        error["rate" + mainIndex] = "Rate is require";
      }

      if (row?.setId) {
        // //check size entered or not
        const sizefilledData = row?.sizeQuantity?.filter(
          (size) => size?.quantity != "" && size?.quantity > 0
        );
        if (!sizefilledData?.length) {
          setsWiseSizeOptions?.[row?.setId]?.map((size) => {
            error["size" + mainIndex + size] = "Size is require";
          });
        }
      }
    });
    return error;
  };

  const checkIncludeError = (key) => {
    return Object.keys(validateData(productRow)).includes(key) && submitted;
  };

  const submit = (data) => {
    setSubmitted(true);
    if (!Object.keys(validateData(productRow)).length) {
      // data.productData = productRow;
      setSaveButtonLoader(true);
      data.productData = productRow?.map((e) => {
        return { ...e, sizeQuantity: e.sizeQuantity?.filter((s) => s?.sizeId) };
      });
      data.productData = JSON.stringify(data?.productData);
      data.quantity = productRow.reduce((accumulator, object) => {
        return Number(accumulator) + Number(object.totalQuantity);
      }, 0);
      data.totalAmount = productRow.reduce((accumulator, object) => {
        return (
          Number(accumulator) +
          Number(object.totalQuantity) * Number(object.rate)
        );
      }, 0);
      data.deliveryDate = ContainerIndex.convertDate(data?.deliveryDate || "");
      data.orderDate = ContainerIndex.convertDate(data?.orderDate || "");
      data.poDate = ContainerIndex.convertDate(data?.poDate || "");
      if (editId) {
        data.id = editId;
      }
      if (removedProductId?.length) {
        data.removedProductId = removedProductId;
      }

      dispatch(createSalesOrderAction(token, data, navigate)).finally(() => {
        setSaveButtonLoader(false);
      });
    } else {
      setSaveButtonLoader(false);
    }
  };

  const setAgentAndTransportOfparty = (partyId) => {
    const filteredAccountmaster = sundryDebitors?.filter(
      (ele) => ele?.id == partyId
    );
    if (filteredAccountmaster?.[0]?.brokerId) {
      setValue("agent", filteredAccountmaster?.[0]?.brokerId);
    }
    if (filteredAccountmaster?.[0]?.transportId) {
      setValue("transport", Number(filteredAccountmaster?.[0]?.transportId));
    }
  };

  const parseData = (data) => {
    try {
      // if plain js
      if (data) {
        let result = JSON.parse(data);
        return result;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  //set set wise size data
  useEffect(() => {
    let setSizeArray = [];
    setMasterWithSize?.forEach((element) => {
      let result = parseData(element?.data);
      let sizeArray = result?.map((a) => a.sizeId);
      setSizeArray[element?.id] = sizeArray;
    });
    setSetsWiseSizeOptions(setSizeArray);
  }, [setMasterWithSize]);

  //set product wise color data
  useEffect(() => {
    let prodcuctColorArray = [];
    salesPrerequisiteData?.forEach((element) => {
      let result = parseData(element?.data);
      prodcuctColorArray[element?.productCode] = result;
    });
    setProductWiseColorOptions(prodcuctColorArray);
  }, [salesPrerequisiteData]);

  function generateOrderNumber(str = "0000000000") {
    if ([...str].every((e) => !+e)) {
      return str.slice(0, str.length - 1) + 1;
    }
    let extraZero = "";
    let lastIndex = str.length - 1;
    for (let i = 0; i < str.length; i++) {
      if (+str[i] !== 0) {
        lastIndex = i;
        break;
      } else {
        extraZero += str[i];
      }
    }
    return extraZero + (+str.slice(lastIndex, str.length) + 1);
  }

  const getPreviousOrderNo = () => {
    getSalesOrderNo(token).then((response) => {
      if (response.status === 201 || response.status === 200) {
        const newOrderNo = generateOrderNumber(
          response?.data?.[0]?.orderNo || "0000000000"
        );
        setValue("orderNo", newOrderNo);
      }
    });
  };

  useEffect(() => {
    if (!editId) {
      getPreviousOrderNo();
    }
  }, []);

  const addProductRow = async () => {
    await setProductRow((data) => [
      ...data,
      {
        id: Math.random(),
        productId: "",
        description: "",
        colorId: "",
        setId: "",
        sizeQuantity: [],
        mrp: "",
        rate: "",
        priceId: 0,
        totalQuantity: "",
        remarks: "",
      },
    ]);
    productRef.current.focus();
  };
  const removeProductRow = (id) => {
    setProductRow((data) => {
      let arr = [...data];
      return arr.filter((e) => e?.id !== id);
    });

    if (id >= 1) {
      setRemovedProductId([...removedProductId, id]);
    }
  };

  const getProductSetWiseRateAmount = (productId, setId, index) => {
    getProductSetRateAmount(token, productId, setId).then((response) => {
      if (response.status === 201 || response.status === 200) {
        setProductRow((data) => {
          let arr = [...data];
          arr[index]["rate"] = response?.data?.[0]?.rate;
          arr[index]["mrp"] = response?.data?.[0]?.MRP;
          arr[index]["priceId"] = response?.data?.[0]?.id;

          return arr;
        });
      }
    });
  };

  const handleChangeProductData = (index, key, value, sizeId = "") => {
    if (sizeId != "") {
      setProductRow((data) => {
        let arr = [...data];
        arr[index][key][sizeId] = {
          sizeId: sizeId,
          quantity: value,
          id: arr[index][key][sizeId]?.id,
        };
        return arr;
      });

      setProductRow((data) => {
        let arr = [...data];
        arr[index]["totalQuantity"] = arr[index]?.[key]?.reduce(
          (accumulator, object) => {
            return Number(accumulator) + Number(object.quantity);
          },
          0
        );
        return arr;
      });
    } else {
      setProductRow((data) => {
        let arr = [...data];
        arr[index][key] = value;
        if (key == "productId") {
          arr[index]["colorId"] = "";
        }
        if (key == "setId") {
          arr[index]["sizeQuantity"] = [];
          arr[index]["mrp"] = "";
          arr[index]["rate"] = "";
          arr[index]["totalQuantity"] = "";
        }
        return arr;
      });
    }

    // to set image of product
    if (key == "productId" || key == "colorId") {
      let imageData = productWiseColorOptions?.[
        productRow?.[index]?.["productId"]
      ]?.find((ele) => ele?.color == productRow?.[index]?.["colorId"]);
      if (imageData?.image) {
        setPreviewImage(imageData?.image);
      }

      // Get product/color wise stock and pending sales
      if (
        productRow?.[index]?.["productId"] &&
        productRow?.[index]?.["colorId"]
      ) {
        getSingleProductStock(
          productRow?.[index]?.["productId"],
          productRow?.[index]?.["colorId"]
        );
      }
    }

    // set item description dynamic
    if (key == "productId") {
      let productData = salesPrerequisiteData.find(
        (ele) => ele?.productCode == value
      );

      setProductRow((data) => {
        let arr = [...data];
        arr[index]["description"] = productData?.ProdDescription || "";
        return arr;
      });
    }

    // Get product and set wise rate and amount
    if (key == "productId" || key == "setId") {
      if (
        productRow?.[index]?.["productId"] &&
        productRow?.[index]?.["setId"]
      ) {
        getProductSetWiseRateAmount(
          productRow?.[index]?.["productId"],
          productRow?.[index]?.["setId"],
          index
        );

        // setsWiseSizeOptions?.[productRow?.[index]?.["setId"]]?.map((sizeId) => {
        //   setProductRow((data) => {
        //     let arr = [...data];
        //     arr[index]["sizeQuantity"][sizeId] = {
        //       sizeId: sizeId,
        //       quantity: 1,
        //       id: "",
        //     };
        //     return arr;
        //   });
        // })
      }
    }

    // Get product and set wise rate and amount
    if (key == "setId") {
      // if (
      //   productRow?.[index]?.["productId"] &&
      //   productRow?.[index]?.["setId"]
      // ) {
      getProductSetWiseRateAmount(
        productRow?.[index]?.["productId"],
        value,
        index
      );

      setsWiseSizeOptions?.[value]?.map((sizeId, i) => {
        setProductRow((data) => {
          let arr = [...data];
          arr[index]["sizeQuantity"][sizeId] = {
            sizeId: sizeId,
            quantity: 1,
            id: "",
          };
          arr[index]["totalQuantity"] = i + 1;
          return arr;
        });
      });
      // }
    }
  };

  const getSingleProductStock = (productId, colorId) => {
    getSingleProductStockApiHandler(token, productId, colorId).then((data) => {
      if (data.status === 201 || data.status === 200) {
        setProductStock(data?.data?.currentStock);
        setPendingSales(data?.data?.pendingSales);
      } else {
        setProductStock([]);
        setPendingSales([]);
      }
    });
  };

  const previewProductImage = (index) => {
    let imageData = productWiseColorOptions?.[
      productRow?.[index]?.["productId"]
    ]?.find((ele) => ele?.color == productRow?.[index]?.["colorId"]);
    if (imageData?.image) {
      setPreviewImage(imageData?.image);
    }

    // Get product/color wise stock and pending sales
    if (
      productRow?.[index]?.["productId"] &&
      productRow?.[index]?.["colorId"]
    ) {
      getSingleProductStock(
        productRow?.[index]?.["productId"],
        productRow?.[index]?.["colorId"]
      );
    }
  };

  const openBarcodeModal = (index) => {
    setBarcodeIndex(index);
    setOpen(true);
  };
  if (loading) {
    <LoadingSpinner></LoadingSpinner>;
  } else {
    return (
      <div>
        <Index.Box className="main_content">
          <Index.Box className="title_top_fix">
            <Index.Box className="flex_justify">
              <Index.Typography variant="h4" component="h4">
                Sales Order
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="web_main_content">
            <Index.Box className="over_text_scroll">
              <Index.Box className="admin-dashboard-list-row">
                <form className="form-content" onSubmit={handleSubmit(submit)}>
                  <Index.Box
                    className="report-main-box report-main-box-set grid-main"
                    sx={{ width: 1 }}
                  >
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      className="sales-order-row"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="label_control">
                                order no
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="custom_form_control"
                                disabled
                                value={watch("orderNo")}
                                {...register("orderNo", {
                                  required: "Order no is required.",
                                })}
                                error={Boolean(errors.orderNo)}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="label_control">
                                Principal Party
                              </Index.FormHelperText>
                              <Index.Box className="dropdown-box auto-drop-box">
                                <Controller
                                  control={control}
                                  {...register("principalParty", {
                                    // required:"Princiapl party is required.",
                                  })}
                                  render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                      className="custom_form_control"
                                      // value={
                                      //   sundryDebitorsPrincipal[
                                      //     sundryDebitorsPrincipal?.findIndex(
                                      //       (e) => e.id == value
                                      //     )
                                      //   ]
                                      // }
                                      value={
                                        value
                                          ? sundryDebitorsPrincipal?.find(
                                              (o) => o.id === value
                                            )
                                          : value || null
                                      }
                                      options={sundryDebitorsPrincipal || []}
                                      autoHighlight
                                      onChange={(e, value) => {
                                        onChange(value?.id ? value?.id : "");
                                      }}
                                      getOptionLabel={(option) =>
                                        option?.accountName
                                      }
                                      renderOption={(props, option) => (
                                        <Index.Box
                                          component="li"
                                          sx={{
                                            "& > img": {
                                              mr: 2,
                                              flexShrink: 0,
                                            },
                                          }}
                                          {...props}
                                        >
                                          {option?.accountName}
                                        </Index.Box>
                                      )}
                                      renderInput={(params) => (
                                        <Index.TextField
                                          fullWidth
                                          error={Boolean(
                                            errors?.principalParty
                                          )}
                                          className="inner_custom_form_control"
                                          {...params}
                                          size="small"
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group date-of-purchase">
                              <Index.FormHelperText className="label_control">
                                order date{" "}
                                <span className="requried-star">*</span>
                              </Index.FormHelperText>

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <Controller
                                    control={control}
                                    {...register("orderDate", {
                                      required: "Order date is required.",
                                    })}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <MobileDatePicker
                                        fullWidth
                                        disableFuture={true}
                                        className="custom_form_control"
                                        minDate={dayjs(selectedYearStart)}
                                        maxDate={dayjs(selectedYearEnd)}
                                        format="DD/MM/YYYY"
                                        value={value}
                                        onChange={(newValue) => {
                                          setValue("orderDate", newValue);
                                        }}
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            error: Boolean(errors.orderDate),
                                            // error : true
                                          },
                                        }}
                                        error={Boolean(errors.orderDate)}
                                      />
                                    )}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="label_control">
                                Party <span className="requried-star">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="dropdown-box auto-drop-box">
                                <Controller
                                  control={control}
                                  {...register("party", {
                                    required: "Party is required.",
                                  })}
                                  render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                      className="custom_form_control"
                                      // defaultValue={
                                      //   sundryDebitors[
                                      //     sundryDebitors?.findIndex(
                                      //       (e) => e.id == value
                                      //     )
                                      //   ]
                                      // }
                                      value={
                                        value
                                          ? sundryDebitors.find(
                                              (o) => o.id === value
                                            )
                                          : value || null
                                      }
                                      options={sundryDebitors}
                                      autoHighlight
                                      onChange={(e, value) => {
                                        onChange(value?.id ? value?.id : "");

                                        setAgentAndTransportOfparty(value?.id);
                                      }}
                                      getOptionLabel={(option) =>
                                        option?.accountName
                                      }
                                      renderOption={(props, option) => (
                                        <Index.Box
                                          component="li"
                                          sx={{
                                            "& > img": {
                                              mr: 2,
                                              flexShrink: 0,
                                            },
                                          }}
                                          {...props}
                                        >
                                          {option?.accountName}
                                        </Index.Box>
                                      )}
                                      renderInput={(params) => (
                                        <Index.TextField
                                          fullWidth
                                          error={Boolean(errors?.party)}
                                          className="inner_custom_form_control"
                                          {...params}
                                          size="small"
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group date-of-purchase">
                              <Index.FormHelperText className="label_control">
                                Delivery date
                              </Index.FormHelperText>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <Controller
                                    control={control}
                                    {...register("deliveryDate", {
                                      required: "Delivert date is required.",
                                    })}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <MobileDatePicker
                                        fullWidth
                                        className="custom_form_control"
                                        // disableFuture={true}
                                        minDate={dayjs(selectedYearStart)}
                                        maxDate={dayjs(selectedYearEnd)}
                                        format="DD/MM/YYYY"
                                        value={value}
                                        onChange={(newValue) => {
                                          setValue("deliveryDate", newValue);
                                        }}
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            error: Boolean(errors.deliveryDate),
                                            // error : true
                                          },
                                        }}
                                        error={Boolean(errors.deliveryDate)}
                                      />
                                    )}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="label_control">
                                party Po no.
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="custom_form_control"
                                // defaultValue=""
                                value={watch("partyPONo")}
                                {...register("partyPONo", {
                                  // required: "PO no is required.",
                                })}
                                error={Boolean(errors.partyPONo)}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group date-of-purchase">
                              <Index.FormHelperText className="label_control">
                                party Po date
                              </Index.FormHelperText>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <Controller
                                    control={control}
                                    {...register("poDate", {
                                      required: "PO date is required.",
                                    })}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <MobileDatePicker
                                        className="custom_form_control"
                                        disableFuture={true}
                                        minDate={dayjs(selectedYearStart)}
                                        maxDate={dayjs(selectedYearEnd)}
                                        format="DD/MM/YYYY"
                                        value={value}
                                        onChange={(newValue) => {
                                          setValue("poDate", newValue);
                                        }}
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            error: Boolean(errors.poDate),
                                            // error : true
                                          },
                                        }}
                                        error={Boolean(errors.poDate)}
                                      />
                                    )}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="label_control">
                                order taken by
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="custom_form_control"
                                defaultValue={adminProfile?.Name}
                                disabled
                              />
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="label_control">
                                agent <span className="requried-star">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="dropdown-box auto-drop-box">
                                <Controller
                                  control={control}
                                  {...register("agent", {
                                    required: "Agent is required.",
                                  })}
                                  render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                      className="custom_form_control"
                                      value={
                                        value
                                          ? agentList.find(
                                              (o) => o.id === value
                                            )
                                          : value || null
                                      }
                                      options={agentList}
                                      autoHighlight
                                      onChange={(e, value) => {
                                        onChange(value?.id ? value?.id : "");
                                      }}
                                      getOptionLabel={(option) =>
                                        option?.accountName
                                      }
                                      renderOption={(props, option) => (
                                        <Index.Box
                                          component="li"
                                          sx={{
                                            "& > img": {
                                              mr: 2,
                                              flexShrink: 0,
                                            },
                                          }}
                                          {...props}
                                        >
                                          {option?.accountName}
                                        </Index.Box>
                                      )}
                                      renderInput={(params) => (
                                        <Index.TextField
                                          fullWidth
                                          error={Boolean(errors?.agent)}
                                          className="inner_custom_form_control"
                                          {...params}
                                          size="small"
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="label_control">
                                transport
                              </Index.FormHelperText>
                              <Index.Box className="dropdown-box auto-drop-box">
                                <Controller
                                  control={control}
                                  {...register("transport", {})}
                                  render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                      className="custom_form_control"
                                      value={
                                        value
                                          ? transportList.find(
                                              (o) => o.id === value
                                            )
                                          : value || null
                                      }
                                      options={transportList}
                                      autoHighlight
                                      onChange={(e, value) => {
                                        onChange(value?.id ? value?.id : "");
                                      }}
                                      getOptionLabel={(option) =>
                                        option?.accountName
                                      }
                                      renderOption={(props, option) => (
                                        <Index.Box
                                          component="li"
                                          sx={{
                                            "& > img": {
                                              mr: 2,
                                              flexShrink: 0,
                                            },
                                          }}
                                          {...props}
                                        >
                                          {option?.accountName}
                                        </Index.Box>
                                      )}
                                      renderInput={(params) => (
                                        <Index.TextField
                                          fullWidth
                                          error={Boolean(errors?.transport)}
                                          className="inner_custom_form_control"
                                          {...params}
                                          size="small"
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="label_control">
                                Delivery at
                              </Index.FormHelperText>
                              <Index.Box className="dropdown-box auto-drop-box">
                                <Controller
                                  control={control}
                                  {...register("deliveryAt", {
                                    // required: "Delivery at is required.",
                                  })}
                                  render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                      className="custom_form_control"
                                      // defaultValue={
                                      //   sundryDebitors[
                                      //     sundryDebitors?.findIndex(
                                      //       (e) => e.id == value
                                      //     )
                                      //   ]
                                      // }
                                      value={
                                        value
                                          ? sundryDebitors.find(
                                              (o) => o.id === value
                                            )
                                          : value || null
                                      }
                                      options={sundryDebitors}
                                      autoHighlight
                                      onChange={(e, value) => {
                                        onChange(value?.id ? value?.id : "");
                                      }}
                                      getOptionLabel={(option) =>
                                        option?.accountName
                                      }
                                      renderOption={(props, option) => (
                                        <Index.Box
                                          component="li"
                                          sx={{
                                            "& > img": {
                                              mr: 2,
                                              flexShrink: 0,
                                            },
                                          }}
                                          {...props}
                                        >
                                          {option?.accountName}
                                        </Index.Box>
                                      )}
                                      renderInput={(params) => (
                                        <Index.TextField
                                          fullWidth
                                          error={Boolean(errors?.deliveryAt)}
                                          className="inner_custom_form_control"
                                          {...params}
                                          size="small"
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Item className="grid-item">
                          <Index.Box className="input-box">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="label_control">
                                remarks
                              </Index.FormHelperText>
                              <Controller
                                control={control}
                                {...register("remarks", {
                                  // required: "Remarks is required.",
                                })}
                                render={({ field: { onChange, value } }) => (
                                  <TextareaAutosize
                                    className={`custom_form_control_textarea   ${
                                      errors?.remarks
                                        ? "remark-textarea-error"
                                        : ""
                                    }`}
                                    value={value}
                                    aria-label="empty textarea"
                                    placeholder=""
                                    minRows="2"
                                    error={Boolean(errors?.remarks)}
                                    onChange={onChange}
                                  />
                                )}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="sales-order-scroll-row">
                      <Index.Box className="page-table-main genrate-taxsalesentry-scrollbale-table-main">
                        <TableContainer
                          component={Paper}
                          className="table-container scrollable-table-container"
                          sx={{ maxHeight: 255 }}
                        >
                          <Table
                            className="table"
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    No.
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box"></Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Barcode
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Item Code
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Description
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Color
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Set
                                  </Index.Box>
                                </TableCell>

                                {getSizeMaster
                                  ?.sort((a, b) => a.type - b.type)
                                  ?.map((row, index) => (
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="table-th"
                                      key={row?.id}
                                    >
                                      <Index.Box className="scrollable-box">
                                        {row?.size_name}
                                      </Index.Box>
                                    </TableCell>
                                  ))}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Total Qty.
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    MRP
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Rate
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Remark
                                  </Index.Box>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="scrollable-box">
                                    Action
                                  </Index.Box>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {productRow?.map((row, index) => (
                                <TableRow key={row?.id}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      {index + 1}
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="anchor-link table-td img-icon-box"
                                    onClick={() => previewProductImage(index)}
                                  >
                                    <Index.Box className="barcode-box-main">
                                      {/* {index + 1} */}
                                      <ContainerIndex.Tooltip
                                        title="Preview Image"
                                        placement="top"
                                      >
                                        <ContainerIndex.ImageIcon color="primary" />
                                      </ContainerIndex.Tooltip>
                                    </Index.Box>
                                  </TableCell>

                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      <Index.Box className=" linear-btn-main barcode-icon-box ">
                                        <Index.Box className="barcode-box-main">
                                          <ContainerIndex.Tooltip
                                            title="Scan Barcode"
                                            placement="top"
                                          >
                                            <ContainerIndex.QrCodeScannerIcon
                                              color="primary"
                                              onClick={() =>
                                                openBarcodeModal(index)
                                              }
                                            />
                                          </ContainerIndex.Tooltip>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      <Index.Box className="form-group">
                                        <Index.Box className="dropdown-box auto-drop-box">
                                          <Autocomplete
                                            className="custom_form_control"
                                            value={
                                              salesPrerequisiteData?.[
                                                salesPrerequisiteData?.findIndex(
                                                  (e) =>
                                                    e.productCode ==
                                                    productRow?.[index]
                                                      ?.productId
                                                )
                                              ] || null
                                            }
                                            options={
                                              salesPrerequisiteData || []
                                            }
                                            autoHighlight
                                            onChange={(e, newValue) => {
                                              handleChangeProductData(
                                                index,
                                                "productId",
                                                newValue?.productCode
                                              );
                                            }}
                                            getOptionLabel={(option) =>
                                              `${option?.ProductName}(${option?.productCode})`
                                            }
                                            renderOption={(props, option) => (
                                              <Index.Box
                                                component="li"
                                                sx={{
                                                  "& > img": {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                  },
                                                }}
                                                {...props}
                                              >
                                                {`${option?.ProductName}(${option?.productCode})`}
                                              </Index.Box>
                                            )}
                                            renderInput={(params) => (
                                              <Index.TextField
                                                fullWidth
                                                error={checkIncludeError(
                                                  `productId${index}`
                                                )}
                                                className="inner_custom_form_control"
                                                {...params}
                                                size="small"
                                                variant="outlined"
                                                inputRef={
                                                  productRow?.length - 1 ==
                                                  index
                                                    ? productRef
                                                    : ""
                                                }
                                              />
                                            )}
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>

                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      <Index.Box className="input-box">
                                        <Index.Box className="form-group">
                                          <Index.TextField
                                            fullWidth
                                            id="fullWidth"
                                            className="custom_form_control"
                                            value={row?.description}
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            onChange={(e) =>
                                              handleChangeProductData(
                                                index,
                                                "description",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      {" "}
                                      <Index.Box className="form-group">
                                        <Index.Box className="dropdown-box auto-drop-box">
                                          <Autocomplete
                                            className="custom_form_control"
                                            value={
                                              productWiseColorOptions?.[
                                                productRow?.[index]?.productId
                                              ]?.find(
                                                (o) =>
                                                  o.color ==
                                                  productRow?.[index]?.colorId
                                              ) || null
                                            }
                                            options={
                                              productWiseColorOptions?.[
                                                productRow?.[index]?.productId
                                              ] || []
                                            }
                                            autoHighlight
                                            onChange={(e, newValue) => {
                                              handleChangeProductData(
                                                index,
                                                "colorId",
                                                newValue?.color
                                              );
                                            }}
                                            getOptionLabel={(option) =>
                                              option?.colorName
                                            }
                                            renderOption={(props, option) => (
                                              <Index.Box
                                                component="li"
                                                sx={{
                                                  "& > img": {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                  },
                                                }}
                                                {...props}
                                              >
                                                {option?.colorName}
                                              </Index.Box>
                                            )}
                                            renderInput={(params) => (
                                              <Index.TextField
                                                fullWidth
                                                className="inner_custom_form_control"
                                                {...params}
                                                error={checkIncludeError(
                                                  `colorId${index}`
                                                )}
                                                size="small"
                                                variant="outlined"
                                              />
                                            )}
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      {" "}
                                      <Index.Box className="form-group">
                                        <Index.Box className="dropdown-box auto-drop-box">
                                          <Autocomplete
                                            className="custom_form_control"
                                            value={
                                              setMasterWithSize?.find(
                                                (o) =>
                                                  o.id ==
                                                  productRow?.[index]?.setId
                                              ) || null
                                            }
                                            options={setMasterWithSize || []}
                                            autoHighlight
                                            onChange={(e, newValue) => {
                                              handleChangeProductData(
                                                index,
                                                "setId",
                                                newValue?.id
                                              );
                                            }}
                                            getOptionLabel={(option) =>
                                              option?.setName
                                            }
                                            getOptionDisabled={(option) => {
                                              const foundIndex =
                                                productRow.findIndex(
                                                  (ele, i) => {
                                                    return (
                                                      ele?.productId ===
                                                        productRow[index]
                                                          ?.productId &&
                                                      ele?.colorId ===
                                                        productRow[index]
                                                          ?.colorId &&
                                                      ele?.setId ===
                                                        option?.id &&
                                                      index !== i
                                                    );
                                                  }
                                                );
                                              return foundIndex !== -1;
                                            }}
                                            renderOption={(props, option) => (
                                              <Index.Box
                                                component="li"
                                                sx={{
                                                  "& > img": {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                  },
                                                }}
                                                {...props}
                                              >
                                                {option?.setName}
                                              </Index.Box>
                                            )}
                                            renderInput={(params) => (
                                              <Index.TextField
                                                fullWidth
                                                error={checkIncludeError(
                                                  `setId${index}`
                                                )}
                                                className="inner_custom_form_control"
                                                {...params}
                                                size="small"
                                                variant="outlined"
                                              />
                                            )}
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>
                                  {getSizeMaster?.map((row, i) => (
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      className="table-td"
                                      key={row?.size_code}
                                    >
                                      <Index.Box className="scrollable-box">
                                        <Index.Box className="input-box">
                                          <Index.Box className="form-group">
                                            <Index.TextField
                                              fullWidth
                                              type="number"
                                              id="fullWidth"
                                              className={`custom_form_control
                                          ${
                                            !setsWiseSizeOptions?.[
                                              productRow?.[index]?.setId
                                            ]?.includes(row?.size_code)
                                              ? ""
                                              : "size-enabled-input"
                                          }`}
                                              value={
                                                productRow?.[index]
                                                  ?.sizeQuantity?.[
                                                  row?.size_code
                                                ]?.quantity || ""
                                              }
                                              disabled={
                                                !setsWiseSizeOptions?.[
                                                  productRow?.[index]?.setId
                                                ]?.includes(row?.size_code)
                                              }
                                              onChange={(e) =>
                                                handleChangeProductData(
                                                  index,
                                                  "sizeQuantity",
                                                  e.target.value,
                                                  row?.size_code
                                                )
                                              }
                                              error={checkIncludeError(
                                                `size${index}${row?.size_code}`
                                              )}
                                            />
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </TableCell>
                                  ))}

                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      <Index.Box className="input-box">
                                        <Index.Box className="form-group">
                                          <Index.TextField
                                            fullWidth
                                            id="fullWidth"
                                            className="custom_form_control"
                                            value={
                                              productRow?.[index]
                                                ?.totalQuantity || ""
                                            }
                                            disabled
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      <Index.Box className="input-box">
                                        <Index.Box className="form-group">
                                          <Index.TextField
                                            fullWidth
                                            type="number"
                                            id="fullWidth"
                                            className="custom_form_control"
                                            value={
                                              productRow?.[index]?.mrp || ""
                                            }
                                            onChange={(e) =>
                                              handleChangeProductData(
                                                index,
                                                "mrp",
                                                e.target.value
                                              )
                                            }
                                            error={checkIncludeError(
                                              `mrp${index}`
                                            )}
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>

                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      <Index.Box className="input-box">
                                        <Index.Box className="form-group">
                                          <Index.TextField
                                            fullWidth
                                            type="number"
                                            id="fullWidth"
                                            className="custom_form_control"
                                            value={
                                              productRow?.[index]?.rate || ""
                                            }
                                            onChange={(e) =>
                                              handleChangeProductData(
                                                index,
                                                "rate",
                                                e.target.value
                                              )
                                            }
                                            error={checkIncludeError(
                                              `rate${index}`
                                            )}
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="scrollable-box">
                                      <Index.Box className="input-box">
                                        <Index.Box className="form-group">
                                          <TextareaAutosize
                                            value={
                                              productRow?.[index]?.remarks || ""
                                            }
                                            className="custom_form_control_textarea scrollable-textarea"
                                            aria-label="empty textarea"
                                            placeholder=""
                                            // minRows="1"
                                            onChange={(e) =>
                                              handleChangeProductData(
                                                index,
                                                "remarks",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>
                                  <TableCell className="action-plus table-cell-td">
                                    <Index.Box className="scrollable-box">
                                      <Index.Box className="add-more-content-btn userdata-btn-flex">
                                        <>
                                          {productRow?.length > 1 ? (
                                            <Index.Box
                                              onClick={() => {
                                                removeProductRow(row?.id);
                                              }}
                                              className="table-btns table-trash-btn"
                                              disableRipple
                                            >
                                              <img
                                                src={Index.Png.Minus}
                                                className="table-icons"
                                              ></img>
                                            </Index.Box>
                                          ) : (
                                            <></>
                                          )}
                                          {index + 1 == productRow?.length ? (
                                            <Index.Box
                                              className="table-btns table-edit-btn"
                                              disableRipple
                                              onClick={() => {
                                                addProductRow();
                                              }}
                                            >
                                              <img
                                                src={Index.Png.Plus}
                                                className="table-icons"
                                              ></img>
                                            </Index.Box>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      </Index.Box>
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Index.Box>
                      <Index.Box className="gross-row">
                        <Index.FormHelperText className="gross-lable">
                          <b>
                            Total Qty :{" "}
                            {productRow?.reduce(
                              (sum, obj) => sum + obj?.totalQuantity,
                              0
                            ) || 0}
                          </b>
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="card-spacing">
                      <Index.Box className="page-table-main m-30 pakingslip-list-table-main salesorder-img-flex">
                        <TableContainer
                          component={Paper}
                          className="table-container scrollable-table-container"
                          sx={{ maxHeight: 255 }}
                        >
                          <Table
                            className="table"
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="table-th"
                                >
                                  <Index.Box className="table-data-text"></Index.Box>
                                </TableCell>

                                {getSizeMaster?.map((row, index) => (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    className="table-th"
                                    key={row?.id}
                                  >
                                    <Index.Box className="table-data-text">
                                      {row?.size_name}
                                    </Index.Box>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  Stock
                                </TableCell>

                                {getSizeMaster?.map((row, index) => (
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                    key={row?.size_code}
                                  >
                                    <Index.Box className="table-data-text">
                                      {productStock?.find(
                                        (ele) =>
                                          ele?.size_code == row?.size_code
                                      )?.qty || 0}{" "}
                                    </Index.Box>
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  Production
                                </TableCell>
                                {getSizeMaster?.map((row, index) => (
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                    key={row?.id}
                                  >
                                    <Index.Box> </Index.Box>
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  Pending
                                </TableCell>
                                {getSizeMaster?.map((row, index) => (
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-td"
                                    key={row?.size_code}
                                  >
                                    <Index.Box className="table-data-text">
                                      {pendingSales?.find(
                                        (ele) =>
                                          ele?.size_code == row?.size_code
                                      )?.qty || 0}{" "}
                                    </Index.Box>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Index.Box className="salesorder-img-box">
                          <Index.Box className="order-img-main-box gerate-paking-order-img-box">
                            <img
                              src={
                                previewImage
                                  ? process.env.REACT_APP_IMAGE_URL +
                                    previewImage
                                  : Index.Png.test
                              }
                              className="order-img"
                            ></img>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="btn-flex-end">
                      <Index.Box className="secondary-btn-main ">
                        <Index.Button
                          variant=""
                          className="secondary_button cancel_btn"
                          onClick={() => navigate(-1)}
                          disabled={saveButtonLoader}
                        >
                          Cancel
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="btn_linear">
                        <Index.Button
                          variant=""
                          className="linear-btn-main savechange_btn"
                          type="submit"
                          disabled={saveButtonLoader}
                        >
                          {saveButtonLoader ? <Spinner /> : "Save"}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        {/* modal start */}
        <ContainerIndex.Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="common-modal"
        >
          <Index.Box sx={stylemodal} className="modal-content-inner">
            <BarcodeDropdown
              barcodeIndex={barcodeIndex}
              setProductRow={setProductRow}
              productRow={productRow}
              token={token}
              setOpen={setOpen}
              handleChangeProductData={handleChangeProductData}
            />
          </Index.Box>
        </ContainerIndex.Modal>
      </div>
    );
  }
}
