import React, { useState, useEffect, useRef } from "react";
import Index from "../../../../component/Index";
import ContainerIndex from "../../../Index";
import { styled } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import * as XLSX from "xlsx/xlsx.mjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  getSalesOrderReportDataWebviewApiHandler,
  getSalesOrderReportFilterOptionsWebviewApiHandler,
  setOffSalesOrderQtyApiHandler,
  uploadPdfApiHandler,
} from "../../../../services/AdminIndex";
import LoadingSpinner from "../../../../component/LoadingSpinner";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { GetSizeMasterListWebviewAction } from "../../../../redux/admin/action";
import { toast } from "react-toastify";
import Spinner from "../../../../component/Spinner";
import { useParams } from "react-router-dom";

const Item = styled(Index.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function SalesOrderReport() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = Index.useForm();

  const { companyId, selectedYearStart, selectedYearEnd } = useParams();

  const filterRef = useRef();
  const printRef = useRef();
  const dispatch = useDispatch();
  const [sundryDebitors, setSundryDebitors] = useState([]);
  const [sundryDebitorsPrincipal, setSundryDebitorsPrincipal] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [products, setProducts] = useState([]);
  const [colors, setColors] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [saveButtonLoader, setSaveButtonLoader] = useState(false);
  const [filteredSubmitedOptions, setFilteredSubmitedOptions] = useState();
  const reportType = [
    { value: "detailReport", title: "Detail Report" },
    { value: "partyWiseDetail", title: "Party Wise Detail" },
    { value: "partyWiseSummary", title: "Party Wise Summary (Only Pending)" },
    { value: "itemWiseDetail", title: "Item Wise Detail" },
    { value: "itemWiseSummary", title: "Item Wise Summary" },
    { value: "orderValueReport", title: "Order Value Report" },
    {
      value: "principalWiseReport",
      title: "Principal Wise Order Report(Pending)",
    },
  ];
  const { loading, getSizeMaster } = Index.useSelector((state) => {
    return state.ERPAdminReducer;
  });

  useEffect(() => {
    setValue("onlyPending", false);
    dispatch(GetSizeMasterListWebviewAction(1, companyId));
  }, []);

  useEffect(() => {
    setValue("fromDate", dayjs(selectedYearStart));
    setValue("toDate", dayjs(selectedYearEnd));
  }, [selectedYearStart]);

  const generateAndSavePDF = async (printIframe) => {
    const element = printIframe.contentWindow.document.body;

    const canvas = await html2canvas(element, {
      scale: 2,
    });
    const imgData = canvas.toDataURL("image/jpeg", 0.8);

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = imgProps.width * 0.264583;
    const imgHeight = imgProps.height * 0.264583;

    const ratio = imgWidth / pdfWidth;
    const adjustedHeight = imgHeight / ratio;

    if (adjustedHeight <= pdfHeight) {
      pdf.addImage(imgData, "jpeg", 0, 0, pdfWidth, adjustedHeight, "", "FAST");
    } else {
      let position = 0;
      while (position < imgHeight) {
        pdf.addImage(
          imgData,
          "PNG",
          0,
          -position / ratio,
          pdfWidth,
          imgHeight / ratio
        );
        position += pdfHeight * ratio;
        if (position < imgHeight) {
          pdf.addPage();
        }
      }
    }

    const pdfBlob = pdf.output("blob", {
      compress: true,
      fontSize: 12,
      quality: "high",
    });

    const formData = new FormData();
    formData.append("profile", pdfBlob, "generated.pdf");

    try {
      uploadPdfApiHandler(formData).then((response) => {
        if (response.status == 201 || response.status == 200) {
          // window.open(
          //   "http://localhost:3011/api/uploads/profile-1720590930769.pdf"
          // );
          const link = document.createElement("a");
          link.href = response?.data;
          link.download = "report.pdf"; // Specify the file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIdLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    print: async (printIframe) => {
      await generateAndSavePDF(printIframe);
    },
  });

  // Automatically trigger print when data changes and is not empty
  useEffect(() => {
    if (reportData?.length > 0) {
      handlePrint();
    }
  }, [reportData]);

  const handleScrollToTop = (ref) => {
    filterRef.current.scrollIntoView();
  };

  const getDispatchQtyAmount = (qty, amount, dispatchRemainQty, setOffQty) => {
    const dispatchQty =
      Number(qty) - Number(setOffQty || 0) - Number(dispatchRemainQty);

    let dispatchAmount = 0;
    if (amount && dispatchQty) {
      dispatchAmount = (dispatchQty * amount) / qty;
    }
    return dispatchAmount;
  };

  const exportToExcel = () => {
    // Convert table to sheet
    const ws = XLSX?.utils?.table_to_sheet(printRef.current);
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

    if (watch("reportType") == "detailReport") {
      data?.forEach((row) => {
        if (row.length > 1) {
          if (row?.indexOf("Set Off") != -1 || row?.indexOf("Action") != -1) {
            row.pop(); // Remove the last element from each row
          }
        }
      });
    }
    // return false;
    data.splice(2, 0, []);
    const newWs = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX?.utils?.book_new();
    XLSX?.utils?.book_append_sheet(wb, newWs, "Sales-Order");
    XLSX.writeFile(wb, "sales-Order.xlsx");
  };

  const getReportFilterOptions = () => {
    dispatch({ type: "LOADER_START" });
    try {
      getSalesOrderReportFilterOptionsWebviewApiHandler(companyId).then(
        async (data) => {
          dispatch({ type: "LOADER_START" });
          if (data.status === 201 || data.status === 200) {
            setSundryDebitorsPrincipal(data?.data?.sundryDebtorsPrincipal);
            setBrokers(data?.data?.broker);
            setSundryDebitors(data?.data?.party);
            setProducts(data?.data?.product);
            setColors(data?.data?.color);

            dispatch({ type: "LOADER_END" });
          } else {
            dispatch({ type: "ADMIN_ERROR_HANDLER", payload: data });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  //Set off size qty
  // const handleSetOffQty = (id) => {
  //   setOffSalesOrderQtyApiHandler(id).then((response) => {
  //     if (response.status === 201 || response.status === 200) {
  //       toast.success("Set Off quantity successfully");
  //       submit(filteredSubmitedOptions);
  //     } else {
  //       dispatch({ type: "ADMIN_ERROR_HANDLER", payload: response });
  //     }
  //   });
  // };

  useEffect(() => {
    getReportFilterOptions();
  }, []);
  const [isLoading, setIdLoading] = useState(false);
  const submit = (data) => {
    setIdLoading(true);
    setFilteredSubmitedOptions(data);
    if (data.fromDate) {
      data.fromDate = moment(data?.fromDate?.$d).format("YYYY-MM-DD");
    }
    if (data.toDate) {
      data.toDate = moment(data?.toDate?.$d).format("YYYY-MM-DD");
    }
    data.type = data.reportType;
    data.companyId = companyId;
    setSaveButtonLoader(true);
    try {
      getSalesOrderReportDataWebviewApiHandler(data).then(async (response) => {
        setSaveButtonLoader(false);
        dispatch({ type: "LOADER_START" });
        if (response.status === 201 || response.status === 200) {
          const filteredData = response?.data?.filter(
            (ele) => ele?.productData
          );
          let finalData = [];
          if (watch("reportType") == "partyWiseDetail") {
            const data = filteredData?.map((ele) => {
              return {
                ...ele,
                productData: parseData(ele?.productData),
                partyAddress: parseData(ele?.partyAddress)?.[0],
              };
            });

            const result = [];
            data?.forEach((order) => {
              const { party, Broker, partyAddress, productData } = order;

              let partyEntry = result.find(
                (entry) => entry.party === party && entry.Broker === Broker
              );
              if (!partyEntry) {
                partyEntry = {
                  party,
                  Broker,
                  partyAddress,
                  productData: [],
                };
                result.push(partyEntry);
              }

              productData?.forEach((product) => {
                const { ProductName, color_name, sizeQuantity } = product;

                let productEntry = partyEntry.productData.find(
                  (entry) =>
                    entry.ProductName === ProductName &&
                    entry.color_name === color_name
                );
                if (!productEntry) {
                  productEntry = {
                    ProductName,
                    color_name,
                    sizeQuantity: [],
                  };
                  partyEntry.productData.push(productEntry);
                }

                sizeQuantity?.forEach((size) => {
                  const { sizeId, qty, setOffQty, dispatchRemainQty } = size;

                  let sizeEntry = productEntry.sizeQuantity.find(
                    (entry) => entry.sizeId === sizeId
                  );
                  if (!sizeEntry) {
                    sizeEntry = {
                      sizeId,
                      qty: 0,
                      setOffQty: 0,
                      dispatchRemainQty: 0,
                    };
                    productEntry.sizeQuantity.push(sizeEntry);
                  }

                  sizeEntry.qty += qty;
                  sizeEntry.setOffQty += setOffQty;
                  sizeEntry.dispatchRemainQty += dispatchRemainQty;
                });
              });
            });

            finalData = result;
          } else if (watch("reportType") == "partyWiseSummary") {
            const data = filteredData?.map((ele) => {
              return {
                ...ele,
                productData: parseData(ele?.productData),
                partyAddress: parseData(ele?.partyAddress)?.[0],
              };
            });
            const groupedData = data?.reduce((result, item) => {
              const { party, productData } = item;

              const key = `${party}`;

              if (!result[key]) {
                result[key] = { ...item };
              } else {
                productData?.forEach((product, index) => {
                  const existingProduct = result?.[key]?.productData?.find(
                    (p) =>
                      p?.ProductName === product?.ProductName &&
                      p?.color_name === product?.color_name
                  );

                  if (!existingProduct) {
                    result?.[key]?.productData?.push({ ...product });
                  } else {
                    product?.sizeQuantity?.forEach((sizeQty, sizeIndex) => {
                      if (existingProduct.sizeQuantity[sizeIndex]) {
                        existingProduct.sizeQuantity[
                          sizeIndex
                        ].dispatchRemainQty += sizeQty?.dispatchRemainQty;
                      }
                    });
                  }
                });
              }

              return result;
            }, {});

            const groupedArray = Object.values(groupedData);
            finalData = groupedArray;
          } else if (watch("reportType") == "itemWiseDetail") {
            const data = filteredData?.map((ele) => {
              return {
                ...ele,
                productData: parseData(ele?.productData),
                partyAddress: parseData(ele?.partyAddress)?.[0],
              };
            });
            const groupedData = {};

            data?.forEach((order) => {
              order.productData?.forEach((product) => {
                const productKey = `${product.ProductName}_${product.color_name}`;
                const partyKey = order.party;

                if (!groupedData[productKey]) {
                  groupedData[productKey] = {
                    ProductName: product.ProductName,
                    color_name: product.color_name,
                    productData: {},
                  };
                }

                if (!groupedData[productKey].productData[partyKey]) {
                  groupedData[productKey].productData[partyKey] = {
                    party: order.party,
                    partyAddress: order.partyAddress,
                    sizeQuantity: {},
                  };
                }

                product.sizeQuantity?.forEach((size) => {
                  if (
                    !groupedData[productKey].productData[partyKey].sizeQuantity[
                      size.sizeId
                    ]
                  ) {
                    groupedData[productKey].productData[partyKey].sizeQuantity[
                      size.sizeId
                    ] = {
                      id: size.id,
                      sizeId: size.sizeId,
                      qty: 0,
                      dispatchRemainQty: 0,
                      setOffQty: 0,
                    };
                  }

                  groupedData[productKey].productData[partyKey].sizeQuantity[
                    size.sizeId
                  ].qty += size.qty;
                  groupedData[productKey].productData[partyKey].sizeQuantity[
                    size.sizeId
                  ].dispatchRemainQty += size.dispatchRemainQty;
                  groupedData[productKey].productData[partyKey].sizeQuantity[
                    size.sizeId
                  ].setOffQty += size.setOffQty;
                });
              });
            });

            const groupedArray = Object.values(groupedData).map((group) => ({
              ProductName: group.ProductName,
              color_name: group.color_name,
              productData: Object.values(group.productData).map((party) => ({
                party: party.party,
                partyAddress: party.partyAddress,
                sizeQuantity: Object.values(party.sizeQuantity),
              })),
            }));

            finalData = groupedArray;
          } else if (watch("reportType") == "itemWiseSummary") {
            const data = filteredData?.map((ele) => {
              return {
                ...ele,
                productData: parseData(ele?.productData),
                partyAddress: parseData(ele?.partyAddress)?.[0],
              };
            });

            const groupedData = {};

            data?.forEach((order) => {
              order.productData?.forEach((product) => {
                const key = `${product.ProductName}_${product.color_name}`;

                if (!groupedData[key]) {
                  groupedData[key] = {
                    ProductName: product.ProductName,
                    color_name: product.color_name,
                    productData: [],
                  };
                }

                let partyInfo = groupedData[key].productData.find(
                  (partyInfo) => partyInfo.party === order.party
                );

                if (!partyInfo) {
                  partyInfo = {
                    party: order.party,
                    partyAddress: order.partyAddress,
                    sizeQuantity: [],
                  };
                  groupedData[key].productData.push(partyInfo);
                }

                product.sizeQuantity?.forEach((size) => {
                  let sizeInfo = partyInfo.sizeQuantity.find(
                    (sizeInfo) => sizeInfo.sizeId === size.sizeId
                  );
                  if (!sizeInfo) {
                    sizeInfo = {
                      sizeId: size.sizeId,
                      qty: 0,
                      dispatchRemainQty: 0,
                    };
                    partyInfo.sizeQuantity.push(sizeInfo);
                  }

                  sizeInfo.qty += size.qty;
                  sizeInfo.dispatchRemainQty += size.dispatchRemainQty;
                });
              });
            });

            const result = Object.values(groupedData).map((entry) => {
              entry.productData = entry.productData.map((partyInfo) => {
                return {
                  party: partyInfo.party,
                  partyAddress: partyInfo.partyAddress,
                  sizeQuantity: partyInfo.sizeQuantity,
                };
              });
              return entry;
            });

            finalData = result;
          } else {
            filteredData?.map((ele) => {
              let row = {
                ...ele,
                productData: parseData(ele?.productData),
                partyAddress: parseData(ele?.partyAddress)?.[0],
              };
              finalData.push(row);
            });
          }

          setReportData(finalData);
          dispatch({ type: "LOADER_END" });
        } else {
          dispatch({ type: "ADMIN_ERROR_HANDLER", payload: data });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const parseData = (data) => {
    try {
      // if plain js
      if (data) {
        let result = JSON.parse(data);
        return result;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const reportContent = (reportData) => {
    if (watch("reportType") == "detailReport") {
      return (
        <Index.Box className="card-spacing" style={{ display: "none" }}>
          <Index.Box className="page-table-main summary-seles-report-details-table-main">
            <TableContainer
              component={Paper}
              className="table-container summary-table-container"
            >
              <div ref={printRef}>
                <Table
                  aria-label="simple table"
                  className="table summary-table webview-main-container"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                        colSpan="100%"
                      >
                        <Index.Box className="table-summary-bg">
                          <Index.Typography
                            variant="h4"
                            component="h4"
                            className="view-titles"
                          >
                            Sales Order Report
                          </Index.Typography>
                          <Index.Typography
                            variant="h5"
                            component="h5"
                            className="view-subtitle"
                          >
                            (
                            {
                              reportType?.find(
                                (ele) => ele?.value == watch("reportType")
                              )?.title
                            }
                            )
                          </Index.Typography>
                        </Index.Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Product
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Colour
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Size
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Order Qty.
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Disp Qty.
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Bal Qty.
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Set
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Total Qty.
                      </TableCell>
                      {/* <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Action
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData?.map((row) => {
                      return (
                        <>
                          <TableRow key={row?.orderNo}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              colSpan={8}
                              // colSpan={9}
                            >
                              <b className="bold-text">Order No</b> :{" "}
                              <span className="span-data">{row?.orderNo}</span>,{" "}
                              <span className="span-data">Date</span> :{" "}
                              {moment(row?.orderDate).format("DD-MM-YYYY")}
                              <br />
                              <b className="bold-text">Party</b> :{" "}
                              <span className="span-data">
                                {row?.party} ({row?.partyAddress?.address1}
                                {row?.partyAddress?.address2
                                  ? ", " + row?.partyAddress?.address2
                                  : ""}
                                {row?.partyAddress?.address3
                                  ? ", " + row?.partyAddress?.address3
                                  : ""}
                                {row?.partyAddress?.areaName
                                  ? ", " + row?.partyAddress?.areaName
                                  : ""}
                                {row?.partyAddress?.city
                                  ? ", " + row?.partyAddress?.cityName
                                  : ""}
                                {row?.partyAddress?.pin
                                  ? "-" + row?.partyAddress?.pin
                                  : ""}
                                ){row?.partyMobile}
                              </span>
                              <br />
                              <b className="bold-text">Transport</b> :{" "}
                              <span className="span-data">
                                {row?.transport}
                              </span>
                              , <b className="bold-text">Broker</b> :
                              <span className="span-data">{row?.Broker}</span>
                            </TableCell>
                          </TableRow>
                          {row?.productData?.map((product, index) => {
                            return (
                              <>
                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.ProductName}-{product?.setName}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                    // colSpan={8}
                                    colSpan={7}
                                  >
                                    {product?.color_name}
                                  </TableCell>
                                </TableRow>
                                {product?.sizeQuantity?.map((qty, qtyIndex) => {
                                  return (
                                    <>
                                      <TableRow key={qtyIndex}>
                                        <TableCell
                                          component="td"
                                          scope="row"
                                          className="table-cell-td"
                                        ></TableCell>
                                        <TableCell
                                          component="td"
                                          scope="row"
                                          className="table-cell-td"
                                        ></TableCell>
                                        <TableCell
                                          component="td"
                                          scope="row"
                                          className="table-cell-td"
                                        >
                                          {qty.size_name}
                                        </TableCell>
                                        <TableCell
                                          component="td"
                                          scope="row"
                                          className="table-cell-td"
                                        >
                                          {qty?.qty}
                                        </TableCell>
                                        <TableCell
                                          component="td"
                                          scope="row"
                                          className="table-cell-td"
                                        >
                                          {Number(qty?.qty) -
                                            Number(qty?.setOffQty || 0) -
                                            Number(qty?.dispatchRemainQty)}
                                        </TableCell>
                                        <TableCell
                                          component="td"
                                          scope="row"
                                          className="table-cell-td"
                                        >
                                          {qty?.dispatchRemainQty}
                                        </TableCell>
                                        <TableCell
                                          component="td"
                                          scope="row"
                                          className="table-cell-td"
                                        >
                                          {qty?.setOffQty || 0}
                                        </TableCell>
                                        <TableCell
                                          component="td"
                                          scope="row"
                                          className="table-cell-td"
                                        >
                                          {Number(
                                            Number(qty?.qty) -
                                              Number(qty?.setOffQty || 0) -
                                              Number(qty?.dispatchRemainQty)
                                          ) +
                                            Number(qty?.dispatchRemainQty) +
                                            Number(qty?.setOffQty || 0)}
                                        </TableCell>
                                        {/* <TableCell
                                          component="td"
                                          scope="row"
                                          className="table-cell-td"
                                        >
                                          {qty?.dispatchRemainQty > 0 ? (
                                            <Index.Box className="btn_linear">
                                              <Index.Button
                                                variant=""
                                                className="linear-btn-main "
                                                type="button"
                                                // onClick={() =>
                                                //   handleSetOffQty(qty?.id)
                                                // }
                                              >
                                                Set Off
                                              </Index.Button>
                                            </Index.Box>
                                          ) : (
                                            <></>
                                          )}
                                        </TableCell> */}
                                      </TableRow>
                                    </>
                                  );
                                })}
                              </>
                            );
                          })}
                          <TableRow>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            ></TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            ></TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">Total</b>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <Index.Box className="table-data-text">
                                <b className="bold-text">
                                  {row?.productData?.reduce(
                                    (accumulator, currentValue) => {
                                      const sizeQtySum =
                                        currentValue.sizeQuantity?.reduce(
                                          (sum, size) => sum + size.qty,
                                          0
                                        );
                                      return accumulator + sizeQtySum;
                                    },
                                    0
                                  )}
                                </b>
                              </Index.Box>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <Index.Box className="table-data-text">
                                <b className="bold-text">
                                  {row?.productData?.reduce(
                                    (accumulator, currentValue) => {
                                      const sizeQtySum =
                                        currentValue.sizeQuantity?.reduce(
                                          (sum, size) =>
                                            sum +
                                            (Number(size?.qty) -
                                              Number(size?.setOffQty || 0) -
                                              Number(size?.dispatchRemainQty)),
                                          0
                                        );
                                      return accumulator + sizeQtySum;
                                    },
                                    0
                                  )}
                                </b>
                              </Index.Box>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <Index.Box className="table-data-text">
                                <b className="bold-text">
                                  {row?.productData?.reduce(
                                    (accumulator, currentValue) => {
                                      const sizeQtySum =
                                        currentValue.sizeQuantity?.reduce(
                                          (sum, size) =>
                                            sum + size.dispatchRemainQty,
                                          0
                                        );
                                      return accumulator + sizeQtySum;
                                    },
                                    0
                                  )}
                                </b>
                              </Index.Box>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <Index.Box className="table-data-text">
                                <b className="bold-text">
                                  {row?.productData?.reduce(
                                    (accumulator, currentValue) => {
                                      const sizeQtySum =
                                        currentValue.sizeQuantity?.reduce(
                                          (sum, size) =>
                                            sum + Number(size.setOffQty || 0),
                                          0
                                        );
                                      return accumulator + sizeQtySum;
                                    },
                                    0
                                  )}
                                </b>
                              </Index.Box>
                            </TableCell>

                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <Index.Box className="table-data-text">
                                <b className="bold-text">
                                  {row?.productData?.reduce(
                                    (accumulator, currentValue) => {
                                      const sizeQtySum =
                                        currentValue.sizeQuantity?.reduce(
                                          (sum, size) =>
                                            sum +
                                            Number(
                                              Number(size?.qty) -
                                                Number(size?.setOffQty || 0) -
                                                Number(size?.dispatchRemainQty)
                                            ) +
                                            Number(size?.dispatchRemainQty) +
                                            Number(size?.setOffQty || 0),
                                          0
                                        );
                                      return accumulator + sizeQtySum;
                                    },
                                    0
                                  )}
                                </b>
                              </Index.Box>
                            </TableCell>
                            {/* <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <Index.Box className="table-data-text"></Index.Box>
                            </TableCell> */}
                          </TableRow>
                        </>
                      );
                    })}
                    {reportData?.length ? (
                      <TableRow>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text"></Index.Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text"></Index.Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text">
                            <b className="bold-text">Grand Total</b>
                          </Index.Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text">
                            <b className="bold-text">
                              {reportData?.reduce((total, order) => {
                                order.productData?.forEach((product) => {
                                  product.sizeQuantity?.forEach((size) => {
                                    total += size.qty;
                                  });
                                });
                                return total;
                              }, 0)}
                            </b>
                          </Index.Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text">
                            <b className="bold-text">
                              {reportData?.reduce((total, order) => {
                                order.productData?.forEach((product) => {
                                  product.sizeQuantity?.forEach((size) => {
                                    total +=
                                      Number(size?.qty || 0) -
                                      Number(size?.setOffQty || 0) -
                                      Number(size?.dispatchRemainQty || 0);
                                  });
                                });
                                return total;
                              }, 0)}
                            </b>
                          </Index.Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text">
                            <b className="bold-text">
                              {reportData?.reduce((total, order) => {
                                order.productData?.forEach((product) => {
                                  product.sizeQuantity?.forEach((size) => {
                                    total += Number(size?.dispatchRemainQty);
                                  });
                                });
                                return total;
                              }, 0)}
                            </b>
                          </Index.Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text">
                            <b className="bold-text">
                              {reportData?.reduce((total, order) => {
                                order.productData?.forEach((product) => {
                                  product.sizeQuantity?.forEach((size) => {
                                    total += Number(size?.setOffQty || 0);
                                  });
                                });
                                return total;
                              }, 0)}
                            </b>
                          </Index.Box>
                        </TableCell>

                        <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text">
                            <b className="bold-text">
                              {reportData?.reduce((total, order) => {
                                order.productData?.forEach((product) => {
                                  product.sizeQuantity?.forEach((size) => {
                                    total +=
                                      Number(
                                        Number(size?.qty) -
                                          Number(size?.setOffQty || 0) -
                                          Number(size?.dispatchRemainQty)
                                      ) +
                                      Number(size?.dispatchRemainQty) +
                                      Number(size?.setOffQty || 0);
                                  });
                                });
                                return total;
                              }, 0)}
                            </b>
                          </Index.Box>
                        </TableCell>
                        {/* <TableCell
                          component="td"
                          scope="row"
                          className="table-cell-td"
                        >
                          <Index.Box className="table-data-text"></Index.Box>
                        </TableCell> */}
                      </TableRow>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Index.Box>
        </Index.Box>
      );
    } else if (watch("reportType") == "partyWiseDetail") {
      return (
        <Index.Box className="card-spacing" style={{ display: "none" }}>
          <Index.Box className="page-table-main summary-seles-party-wise-table-main">
            <TableContainer
              component={Paper}
              className="table-container summary-table-container"
            >
              <div ref={printRef}>
                <Table
                  aria-label="simple table"
                  className="table summary-table webview-main-container"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                        colSpan="100%"
                      >
                        <Index.Box className="table-summary-bg">
                          <Index.Typography
                            variant="h4"
                            component="h4"
                            className="view-titles"
                          >
                            Sales Order Report
                          </Index.Typography>
                          <Index.Typography
                            variant="h5"
                            component="h5"
                            className="view-subtitle"
                          >
                            (
                            {
                              reportType?.find(
                                (ele) => ele?.value == watch("reportType")
                              )?.title
                            }
                            )
                          </Index.Typography>
                        </Index.Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Product
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Colour
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      ></TableCell>
                      {getSizeMaster
                        ?.sort((a, b) => a.type - b.type)
                        ?.map((row, index) => (
                          <TableCell
                            component="th"
                            scope="row"
                            className="table-cell-th"
                            key={row?.id}
                          >
                            {row?.size_name}
                          </TableCell>
                        ))}

                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Total Qty.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData?.map((row, index) => {
                      return (
                        <>
                          <TableRow key={index}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              colSpan={12}
                            >
                              <b>Party</b> : {row?.party} (
                              {row?.partyAddress?.address1}
                              {row?.partyAddress?.address2
                                ? ", " + row?.partyAddress?.address2
                                : ""}
                              {row?.partyAddress?.address3
                                ? ", " + row?.partyAddress?.address3
                                : ""}
                              {row?.partyAddress?.areaName
                                ? ", " + row?.partyAddress?.areaName
                                : ""}
                              {row?.partyAddress?.city
                                ? ", " + row?.partyAddress?.cityName
                                : ""}
                              {row?.partyAddress?.pin
                                ? "-" + row?.partyAddress?.pin
                                : ""}
                              ){row?.partyMobile}
                              <br />
                              <b>Broker</b> : {row?.Broker}
                            </TableCell>
                          </TableRow>
                          {row?.productData?.map((product, index) => {
                            return (
                              <>
                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.ProductName}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.color_name}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    Order Qty.
                                  </TableCell>

                                  {getSizeMaster
                                    ?.sort((a, b) => a.type - b.type)
                                    ?.map((row, index) => (
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                        key={row?.size_name}
                                      >
                                        <Index.Box className="table-data-text">
                                          {
                                            product?.sizeQuantity?.find(
                                              (ele) =>
                                                ele?.sizeId == row?.size_code
                                            )?.qty
                                          }
                                        </Index.Box>
                                      </TableCell>
                                    ))}
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    <Index.Box className="table-data-text">
                                      {product?.sizeQuantity?.reduce(
                                        (accumulator, row) => {
                                          return accumulator + row?.qty;
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  ></TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  ></TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    Dispatch Qty.
                                  </TableCell>

                                  {getSizeMaster
                                    ?.sort((a, b) => a.type - b.type)
                                    ?.map((row, index) => (
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                        key={row?.size_code}
                                      >
                                        <Index.Box className="table-data-text">
                                          {product?.sizeQuantity?.find(
                                            (ele) =>
                                              ele?.sizeId == row?.size_code
                                          )?.qty
                                            ? Number(
                                                product?.sizeQuantity?.find(
                                                  (ele) =>
                                                    ele?.sizeId ==
                                                    row?.size_code
                                                )?.qty
                                              ) -
                                              Number(
                                                product?.sizeQuantity?.find(
                                                  (ele) =>
                                                    ele?.sizeId ==
                                                    row?.size_code
                                                )?.setOffQty || 0
                                              ) -
                                              Number(
                                                product?.sizeQuantity?.find(
                                                  (ele) =>
                                                    ele?.sizeId ==
                                                    row?.size_code
                                                )?.dispatchRemainQty
                                              )
                                            : ""}
                                        </Index.Box>
                                      </TableCell>
                                    ))}
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    <Index.Box className="table-data-text">
                                      {product?.sizeQuantity?.reduce(
                                        (accumulator, row) => {
                                          return (
                                            accumulator +
                                            (Number(row?.qty) -
                                              Number(row?.setOffQty || 0) -
                                              Number(row?.dispatchRemainQty))
                                          );
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  ></TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  ></TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    SetOff Qty.
                                  </TableCell>

                                  {getSizeMaster
                                    ?.sort((a, b) => a.type - b.type)
                                    ?.map((row, index) => (
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                        key={row?.size_name}
                                      >
                                        <Index.Box className="table-data-text">
                                          {product?.sizeQuantity?.find(
                                            (ele) =>
                                              ele?.sizeId == row?.size_code
                                          )?.setOffQty || 0}
                                        </Index.Box>
                                      </TableCell>
                                    ))}
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    <Index.Box className="table-data-text">
                                      {product?.sizeQuantity?.reduce(
                                        (accumulator, row) => {
                                          return (
                                            accumulator +
                                            Number(row?.setOffQty || 0)
                                          );
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  ></TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  ></TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    Bal Qty.
                                  </TableCell>

                                  {getSizeMaster
                                    ?.sort((a, b) => a.type - b.type)
                                    ?.map((row, index) => (
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                        key={row?.size_name}
                                      >
                                        <Index.Box className="table-data-text">
                                          {
                                            product?.sizeQuantity?.find(
                                              (ele) =>
                                                ele?.sizeId == row?.size_code
                                            )?.dispatchRemainQty
                                          }
                                        </Index.Box>
                                      </TableCell>
                                    ))}
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    <Index.Box className="table-data-text">
                                      {product?.sizeQuantity?.reduce(
                                        (accumulator, row) => {
                                          return (
                                            accumulator + row?.dispatchRemainQty
                                          );
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Index.Box>
        </Index.Box>
      );
    } else if (watch("reportType") == "partyWiseSummary") {
      return (
        <Index.Box className="card-spacing" style={{ display: "none" }}>
          <Index.Box className="page-table-main summary-seles-party-sumary-table-main">
            <TableContainer
              component={Paper}
              className="table-container summary-table-container"
            >
              <div ref={printRef}>
                <Table
                  aria-label="simple table"
                  className="table summary-table webview-main-container"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                        colSpan="100%"
                      >
                        <Index.Box className="table-summary-bg">
                          <Index.Typography
                            variant="h4"
                            component="h4"
                            className="view-titles"
                          >
                            Sales Order Report
                          </Index.Typography>
                          <Index.Typography
                            variant="h5"
                            component="h5"
                            className="view-subtitle"
                          >
                            (
                            {
                              reportType?.find(
                                (ele) => ele?.value == watch("reportType")
                              )?.title
                            }
                            )
                          </Index.Typography>
                        </Index.Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Product
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Colour
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                        colSpan={8}
                      >
                        Size
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      ></TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      ></TableCell>
                      {getSizeMaster
                        ?.sort((a, b) => a.type - b.type)
                        ?.map((row, index) => (
                          <TableCell
                            component="th"
                            scope="row"
                            className="table-cell-th"
                            key={row?.id}
                          >
                            {row?.size_name}
                          </TableCell>
                        ))}

                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Total Qty.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData?.map((row) => {
                      return (
                        <>
                          <TableRow key={row?.orderNo}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              colSpan={9}
                            >
                              <b className="bold-text">Party</b> : {row?.party}{" "}
                              ({row?.partyAddress?.address1}
                              {row?.partyAddress?.address2
                                ? ", " + row?.partyAddress?.address2
                                : ""}
                              {row?.partyAddress?.address3
                                ? ", " + row?.partyAddress?.address3
                                : ""}
                              {row?.partyAddress?.areaName
                                ? ", " + row?.partyAddress?.areaName
                                : ""}
                              {row?.partyAddress?.city
                                ? ", " + row?.partyAddress?.cityName
                                : ""}
                              {row?.partyAddress?.pin
                                ? "-" + row?.partyAddress?.pin
                                : ""}
                              ){row?.partyMobile}
                            </TableCell>
                          </TableRow>
                          {row?.productData?.map((product, index) => {
                            return (
                              <>
                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.ProductName}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.color_name}
                                  </TableCell>

                                  {getSizeMaster
                                    ?.sort((a, b) => a.type - b.type)
                                    ?.map((row, index) => (
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                        key={row?.size_code}
                                      >
                                        <Index.Box className="table-data-text">
                                          {
                                            product?.sizeQuantity?.find(
                                              (ele) =>
                                                ele?.sizeId == row?.size_code
                                            )?.dispatchRemainQty
                                          }
                                        </Index.Box>
                                      </TableCell>
                                    ))}
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    <Index.Box className="table-data-text">
                                      {product?.sizeQuantity?.reduce(
                                        (accumulator, row) => {
                                          return (
                                            accumulator + row?.dispatchRemainQty
                                          );
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                          <>
                            <TableRow>
                              <TableCell
                                component="td"
                                scope="row"
                                className="table-cell-td"
                              ></TableCell>
                              <TableCell
                                component="td"
                                scope="row"
                                className="table-cell-td"
                              >
                                <b className="bold-text">Total</b>
                              </TableCell>

                              {getSizeMaster
                                ?.sort((a, b) => a.type - b.type)
                                ?.map((sizeRow, index) => (
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                    key={sizeRow?.size_code}
                                  >
                                    <Index.Box className="table-data-text">
                                      {row?.productData?.reduce(
                                        (accumulator, product) => {
                                          const sizeQtySum =
                                            product.sizeQuantity?.reduce(
                                              (sum, size) => {
                                                if (
                                                  size?.sizeId ==
                                                  sizeRow?.size_code
                                                ) {
                                                  return (
                                                    sum + size.dispatchRemainQty
                                                  );
                                                } else {
                                                  return sum;
                                                }
                                              },
                                              0
                                            );
                                          return accumulator + sizeQtySum;
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                ))}
                              <TableCell
                                component="td"
                                scope="row"
                                className="table-cell-td"
                              >
                                <Index.Box className="table-data-text">
                                  {row?.productData?.reduce(
                                    (accumulator, product) => {
                                      const sizeQtySum =
                                        product.sizeQuantity?.reduce(
                                          (sum, size) => {
                                            return sum + size.dispatchRemainQty;
                                          },
                                          0
                                        );
                                      return accumulator + sizeQtySum;
                                    },
                                    0
                                  )}
                                </Index.Box>
                              </TableCell>
                            </TableRow>
                          </>
                        </>
                      );
                    })}
                    {reportData?.length ? (
                      <>
                        <TableRow>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          ></TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <b className="bold-text">Grand Total</b>
                          </TableCell>

                          {getSizeMaster
                            ?.sort((a, b) => a.type - b.type)
                            ?.map((sizeRow, index) => (
                              <TableCell
                                component="td"
                                scope="row"
                                className="table-cell-td"
                                key={sizeRow?.size_code}
                              >
                                <Index.Box className="table-data-text">
                                  {reportData?.reduce((sum, entry) => {
                                    entry.productData?.forEach((product) => {
                                      product.sizeQuantity?.forEach((size) => {
                                        if (
                                          size?.sizeId == sizeRow?.size_code
                                        ) {
                                          sum += size.dispatchRemainQty || 0;
                                        }
                                      });
                                    });

                                    return sum;
                                  }, 0)}
                                </Index.Box>
                              </TableCell>
                            ))}
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <Index.Box className="table-data-text">
                              {reportData?.reduce((sum, entry) => {
                                entry.productData?.forEach((product) => {
                                  product.sizeQuantity?.forEach((size) => {
                                    sum += size.dispatchRemainQty || 0;
                                  });
                                });

                                return sum;
                              }, 0)}
                            </Index.Box>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Index.Box>
        </Index.Box>
      );
    } else if (watch("reportType") == "itemWiseDetail") {
      return (
        <Index.Box className="card-spacing" style={{ display: "none" }}>
          <Index.Box className="page-table-main summary-sales-itemvise-details-table-main">
            <TableContainer
              component={Paper}
              className="table-container summary-table-container"
            >
              <div ref={printRef}>
                <Table
                  aria-label="simple table"
                  className="table summary-table webview-main-container"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                        colSpan="100%"
                      >
                        <Index.Box className="table-summary-bg">
                          <Index.Typography
                            variant="h4"
                            component="h4"
                            className="view-titles"
                          >
                            Sales Order Report
                          </Index.Typography>
                          <Index.Typography
                            variant="h5"
                            component="h5"
                            className="view-subtitle"
                          >
                            (
                            {
                              reportType?.find(
                                (ele) => ele?.value == watch("reportType")
                              )?.title
                            }
                            )
                          </Index.Typography>
                        </Index.Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Party
                      </TableCell>
                      {getSizeMaster
                        ?.sort((a, b) => a.type - b.type)
                        ?.map((row, index) => (
                          <TableCell
                            component="th"
                            scope="row"
                            className="table-cell-th"
                            key={row?.id}
                          >
                            {row?.size_name}
                          </TableCell>
                        ))}

                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Total Qty.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData?.map((row, index) => {
                      return (
                        <>
                          <TableRow key={index}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              colSpan="100%"
                            >
                              <b className="bold-text">Product : </b>
                              {row?.ProductName}
                              {", "}
                              <b className="bold-text">Color : </b>
                              {row?.color_name}
                            </TableCell>
                          </TableRow>
                          {row?.productData?.map((product, index) => {
                            return (
                              <>
                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                    colSpan="100%"
                                  >
                                    <b className="bold-text">Party</b> :{" "}
                                    {product?.party} (
                                    {product?.partyAddress?.address1}
                                    {product?.partyAddress?.address2
                                      ? ", " + product?.partyAddress?.address2
                                      : ""}
                                    {product?.partyAddress?.address3
                                      ? ", " + product?.partyAddress?.address3
                                      : ""}
                                    {product?.partyAddress?.areaName
                                      ? ", " + product?.partyAddress?.areaName
                                      : ""}
                                    {product?.partyAddress?.city
                                      ? ", " + product?.partyAddress?.cityName
                                      : ""}
                                    {product?.partyAddress?.pin
                                      ? "-" + product?.partyAddress?.pin
                                      : ""}
                                    ){product?.partyMobile}
                                  </TableCell>
                                </TableRow>
                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    Order Qty.
                                  </TableCell>

                                  {getSizeMaster
                                    ?.sort((a, b) => a.type - b.type)
                                    ?.map((sizeRow, index) => (
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                        key={sizeRow?.size_code}
                                      >
                                        <Index.Box className="table-data-text">
                                          {
                                            product?.sizeQuantity?.find(
                                              (ele) =>
                                                ele?.sizeId ==
                                                sizeRow?.size_code
                                            )?.qty
                                          }
                                        </Index.Box>
                                      </TableCell>
                                    ))}
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    <Index.Box className="table-data-text">
                                      {product?.sizeQuantity?.reduce(
                                        (accumulator, row) => {
                                          return accumulator + row?.qty;
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    Dispatch Qty.
                                  </TableCell>

                                  {getSizeMaster
                                    ?.sort((a, b) => a.type - b.type)
                                    ?.map((sizeRow, index) => (
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                        key={sizeRow?.size_code}
                                      >
                                        <Index.Box className="table-data-text">
                                          {product?.sizeQuantity?.find(
                                            (ele) =>
                                              ele?.sizeId == sizeRow?.size_code
                                          )?.qty
                                            ? Number(
                                                product?.sizeQuantity?.find(
                                                  (ele) =>
                                                    ele?.sizeId ==
                                                    sizeRow?.size_code
                                                )?.qty
                                              ) -
                                              Number(
                                                product?.sizeQuantity?.find(
                                                  (ele) =>
                                                    ele?.sizeId ==
                                                    sizeRow?.size_code
                                                )?.setOffQty || 0
                                              ) -
                                              Number(
                                                product?.sizeQuantity?.find(
                                                  (ele) =>
                                                    ele?.sizeId ==
                                                    sizeRow?.size_code
                                                )?.dispatchRemainQty
                                              )
                                            : ""}
                                        </Index.Box>
                                      </TableCell>
                                    ))}
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    <Index.Box className="table-data-text">
                                      {product?.sizeQuantity?.reduce(
                                        (accumulator, row) => {
                                          return (
                                            accumulator +
                                            (Number(row?.qty) -
                                              Number(row?.setOffQty || 0) -
                                              Number(row?.dispatchRemainQty))
                                          );
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    SetOff Qty.
                                  </TableCell>

                                  {getSizeMaster
                                    ?.sort((a, b) => a.type - b.type)
                                    ?.map((sizeRow, index) => (
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                        key={sizeRow?.size_code}
                                      >
                                        <Index.Box className="table-data-text">
                                          {product?.sizeQuantity?.find(
                                            (ele) =>
                                              ele?.sizeId == sizeRow?.size_code
                                          )?.setOffQty || 0}
                                        </Index.Box>
                                      </TableCell>
                                    ))}
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    <Index.Box className="table-data-text">
                                      {product?.sizeQuantity?.reduce(
                                        (accumulator, row) => {
                                          return (
                                            accumulator +
                                            Number(row?.setOffQty || 0)
                                          );
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    Bal Qty.
                                  </TableCell>

                                  {getSizeMaster
                                    ?.sort((a, b) => a.type - b.type)
                                    ?.map((row, index) => (
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                        key={row?.size_code}
                                      >
                                        <Index.Box className="table-data-text">
                                          {
                                            product?.sizeQuantity?.find(
                                              (ele) =>
                                                ele?.sizeId == row?.size_code
                                            )?.dispatchRemainQty
                                          }
                                        </Index.Box>
                                      </TableCell>
                                    ))}
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    <Index.Box className="table-data-text">
                                      {product?.sizeQuantity?.reduce(
                                        (accumulator, row) => {
                                          return (
                                            accumulator + row?.dispatchRemainQty
                                          );
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Index.Box>
        </Index.Box>
      );
    } else if (watch("reportType") == "itemWiseSummary") {
      return (
        <Index.Box className="card-spacing" style={{ display: "none" }}>
          <Index.Box className="page-table-main summary-itemvise-summary-table-main">
            <TableContainer
              component={Paper}
              className="table-container summary-table-container"
            >
              <div ref={printRef}>
                <Table
                  aria-label="simple table"
                  className="table summary-table webview-main-container"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                        colSpan="100%"
                      >
                        <Index.Box className="table-summary-bg">
                          <Index.Typography
                            variant="h4"
                            component="h4"
                            className="view-titles"
                          >
                            Sales Order Report
                          </Index.Typography>
                          <Index.Typography
                            variant="h5"
                            component="h5"
                            className="view-subtitle"
                          >
                            (
                            {
                              reportType?.find(
                                (ele) => ele?.value == watch("reportType")
                              )?.title
                            }
                            )
                          </Index.Typography>
                        </Index.Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Party Name
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Size
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      ></TableCell>
                      {getSizeMaster
                        ?.sort((a, b) => a.type - b.type)
                        ?.map((row, index) => (
                          <TableCell
                            component="th"
                            scope="row"
                            className="table-cell-th"
                            key={row?.id}
                          >
                            {row?.size_name}
                          </TableCell>
                        ))}

                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Total Qty.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData?.map((row) => {
                      return (
                        <>
                          <TableRow key={row?.orderNo}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              colSpan="100%"
                            >
                              <b>Product : </b>
                              {row?.ProductName}
                              {", "}
                              <b>Color : </b>
                              {row?.color_name}
                            </TableCell>
                          </TableRow>
                          {row?.productData?.map((product, index) => {
                            return (
                              <>
                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.party}
                                  </TableCell>

                                  {getSizeMaster
                                    ?.sort((a, b) => a.type - b.type)
                                    ?.map((row, index) => (
                                      <TableCell
                                        component="td"
                                        scope="row"
                                        className="table-cell-td"
                                        key={row?.size_code}
                                      >
                                        <Index.Box className="table-data-text">
                                          {
                                            product?.sizeQuantity?.find(
                                              (ele) =>
                                                ele?.sizeId == row?.size_code
                                            )?.dispatchRemainQty
                                          }
                                        </Index.Box>
                                      </TableCell>
                                    ))}
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    <Index.Box className="table-data-text">
                                      {product?.sizeQuantity?.reduce(
                                        (accumulator, row) => {
                                          return (
                                            accumulator + row?.dispatchRemainQty
                                          );
                                        },
                                        0
                                      )}
                                    </Index.Box>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                          <>
                            <TableRow>
                              <TableCell
                                component="td"
                                scope="row"
                                className="table-cell-td"
                              >
                                <b className="bold-text">Total</b>
                              </TableCell>

                              {getSizeMaster
                                ?.sort((a, b) => a.type - b.type)
                                ?.map((sizeRow, index) => (
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                    key={sizeRow?.size_code}
                                  >
                                    <Index.Box className="table-data-text">
                                      <b className="bold-text">
                                        {row?.productData?.reduce(
                                          (accumulator, product) => {
                                            const sizeQtySum =
                                              product.sizeQuantity?.reduce(
                                                (sum, size) => {
                                                  if (
                                                    size?.sizeId ==
                                                    sizeRow?.size_code
                                                  ) {
                                                    return (
                                                      sum +
                                                      size.dispatchRemainQty
                                                    );
                                                  } else {
                                                    return sum;
                                                  }
                                                },
                                                0
                                              );
                                            return accumulator + sizeQtySum;
                                          },
                                          0
                                        )}
                                      </b>
                                    </Index.Box>
                                  </TableCell>
                                ))}
                              <TableCell
                                component="td"
                                scope="row"
                                className="table-cell-td"
                              >
                                <Index.Box className="table-data-text">
                                  <b className="bold-text">
                                    {row?.productData?.reduce(
                                      (accumulator, product) => {
                                        const sizeQtySum =
                                          product.sizeQuantity?.reduce(
                                            (sum, size) => {
                                              return (
                                                sum + size.dispatchRemainQty
                                              );
                                            },
                                            0
                                          );
                                        return accumulator + sizeQtySum;
                                      },
                                      0
                                    )}
                                  </b>
                                </Index.Box>
                              </TableCell>
                            </TableRow>
                          </>
                        </>
                      );
                    })}
                    {reportData?.length ? (
                      <>
                        <TableRow>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <b className="bold-text">Grand Total</b>
                          </TableCell>

                          {getSizeMaster
                            ?.sort((a, b) => a.type - b.type)
                            ?.map((sizeRow, index) => (
                              <TableCell
                                component="td"
                                scope="row"
                                className="table-cell-td"
                                key={sizeRow?.size_code}
                              >
                                <Index.Box className="table-data-text">
                                  <b className="bold-text">
                                    {reportData?.reduce((sum, entry) => {
                                      entry.productData?.forEach((product) => {
                                        product.sizeQuantity?.forEach(
                                          (size) => {
                                            if (
                                              size?.sizeId == sizeRow?.size_code
                                            ) {
                                              sum +=
                                                size.dispatchRemainQty || 0;
                                            }
                                          }
                                        );
                                      });

                                      return sum;
                                    }, 0)}
                                  </b>
                                </Index.Box>
                              </TableCell>
                            ))}
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <Index.Box className="table-data-text">
                              <b className="bold-text">
                                {reportData?.reduce((sum, entry) => {
                                  entry.productData?.forEach((product) => {
                                    product.sizeQuantity?.forEach((size) => {
                                      sum += size.dispatchRemainQty || 0;
                                    });
                                  });

                                  return sum;
                                }, 0)}
                              </b>
                            </Index.Box>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Index.Box>
        </Index.Box>
      );
    } else if (watch("reportType") == "orderValueReport") {
      return (
        <Index.Box className="card-spacing" style={{ display: "none" }}>
          <Index.Box className="page-table-main summary-sales-order-value-table-main">
            <TableContainer
              component={Paper}
              className="table-container summary-table-container"
            >
              <div ref={printRef}>
                <Table
                  aria-label="simple table"
                  className="table summary-table webview-main-container"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                        colSpan="100%"
                      >
                        <Index.Box className="table-summary-bg">
                          <Index.Typography
                            variant="h4"
                            component="h4"
                            className="view-titles"
                          >
                            Sales Order Report
                          </Index.Typography>
                          <Index.Typography
                            variant="h4"
                            component="h4"
                            className="view-titles"
                          >
                            (
                            {
                              reportType?.find(
                                (ele) => ele?.value == watch("reportType")
                              )?.title
                            }
                            )
                          </Index.Typography>
                        </Index.Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Order No.
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Order Date
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Party Name
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Broker Name
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Order Qty.
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Order Amount
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Dispatch Qty
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Dispatch Amount
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        SetOff Qty.
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Balance Qty.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData?.map((row) => {
                      return (
                        <>
                          <TableRow key={row?.orderNo}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              colSpan="100%"
                            >
                              <b className="bold-text">Princiapl Party : </b>
                              {row?.party}
                            </TableCell>
                          </TableRow>
                          {row?.productData?.map((product, index) => {
                            return (
                              <>
                                <TableRow key={index}>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.orderNo}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {moment(product?.orderDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.party}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.Broker}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.quantity}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.totalAmount}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {Number(product?.quantity) -
                                      Number(product?.setOffQty || 0) -
                                      Number(product?.dispatchRemainQty)}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {getDispatchQtyAmount(
                                      product?.quantity,
                                      product?.totalAmount,
                                      product?.dispatchRemainQty,
                                      product?.setOffQty
                                    )}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {Number(product?.setOffQty || 0)}
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="table-cell-td"
                                  >
                                    {product?.dispatchRemainQty}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                          <TableRow>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            ></TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            ></TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            ></TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">Total</b>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">
                                {row?.productData?.reduce(
                                  (accumulator, row) => {
                                    return accumulator + row?.quantity;
                                  },
                                  0
                                )}
                              </b>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">
                                {row?.productData?.reduce(
                                  (accumulator, row) => {
                                    return (
                                      accumulator +
                                      (row?.totalAmount ? row?.totalAmount : 0)
                                    );
                                  },
                                  0
                                )}
                              </b>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">
                                {row?.productData?.reduce(
                                  (accumulator, row) => {
                                    return (
                                      accumulator +
                                      (Number(row?.quantity) -
                                        Number(row?.setOffQty || 0) -
                                        Number(row?.dispatchRemainQty))
                                    );
                                  },
                                  0
                                )}
                              </b>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">
                                {row?.productData?.reduce(
                                  (accumulator, row) => {
                                    return (
                                      accumulator +
                                      getDispatchQtyAmount(
                                        row?.quantity,
                                        row?.totalAmount,
                                        row?.dispatchRemainQty,
                                        row?.setOffQty
                                      )
                                    );
                                  },
                                  0
                                )}
                              </b>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">
                                {row?.productData?.reduce(
                                  (accumulator, row) => {
                                    return (
                                      accumulator + Number(row?.setOffQty || 0)
                                    );
                                  },
                                  0
                                )}
                              </b>
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">
                                {row?.productData?.reduce(
                                  (accumulator, row) => {
                                    return accumulator + row?.dispatchRemainQty;
                                  },
                                  0
                                )}
                              </b>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    {reportData?.length ? (
                      <>
                        <TableRow>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          ></TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          ></TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          ></TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <b className="bold-text">Grand Total</b>
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <b className="bold-text">
                              {reportData?.reduce((accumulator, party) => {
                                return (
                                  accumulator +
                                  party.productData?.reduce(
                                    (partyAccumulator, product) => {
                                      return (
                                        partyAccumulator +
                                        (product.quantity || 0)
                                      );
                                    },
                                    0
                                  )
                                );
                              }, 0)}
                            </b>
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <b className="bold-text">
                              {reportData?.reduce((accumulator, party) => {
                                return (
                                  accumulator +
                                  party.productData?.reduce(
                                    (partyAccumulator, product) => {
                                      return (
                                        partyAccumulator +
                                        (product.totalAmount || 0)
                                      );
                                    },
                                    0
                                  )
                                );
                              }, 0)}
                            </b>
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <b className="bold-text">
                              {reportData?.reduce((accumulator, party) => {
                                return (
                                  accumulator +
                                  party.productData?.reduce(
                                    (partyAccumulator, product) => {
                                      return (
                                        partyAccumulator +
                                        (Number(product?.quantity) -
                                          Number(product?.setOffQty || 0) -
                                          Number(product?.dispatchRemainQty) ||
                                          0)
                                      );
                                    },
                                    0
                                  )
                                );
                              }, 0)}
                            </b>
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <b className="bold-text">
                              {reportData?.reduce((accumulator, party) => {
                                return (
                                  accumulator +
                                  party.productData?.reduce(
                                    (partyAccumulator, product) => {
                                      return (
                                        partyAccumulator +
                                        (getDispatchQtyAmount(
                                          product?.quantity,
                                          product?.totalAmount,
                                          product?.dispatchRemainQty,
                                          product?.setOffQty
                                        ) || 0)
                                      );
                                    },
                                    0
                                  )
                                );
                              }, 0)}
                            </b>
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <b className="bold-text">
                              {reportData?.reduce((accumulator, party) => {
                                return (
                                  accumulator +
                                  party.productData?.reduce(
                                    (partyAccumulator, product) => {
                                      return (
                                        partyAccumulator +
                                        (product.setOffQty || 0)
                                      );
                                    },
                                    0
                                  )
                                );
                              }, 0)}
                            </b>
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <b className="bold-text">
                              {reportData?.reduce((accumulator, party) => {
                                return (
                                  accumulator +
                                  party.productData?.reduce(
                                    (partyAccumulator, product) => {
                                      return (
                                        partyAccumulator +
                                        (product.dispatchRemainQty || 0)
                                      );
                                    },
                                    0
                                  )
                                );
                              }, 0)}
                            </b>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Index.Box>
        </Index.Box>
      );
    } else if (watch("reportType") == "principalWiseReport") {
      return (
        <Index.Box className="card-spacing" style={{ display: "none" }}>
          <Index.Box className="page-table-main summary-principlal-vise-table-main">
            <TableContainer
              component={Paper}
              className="table-container summary-table-container"
            >
              <div ref={printRef}>
                <Table
                  aria-label="simple table"
                  className="table summary-table webview-main-container"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                        colSpan="100%"
                      >
                        <Index.Box className="table-summary-bg">
                          <Index.Typography
                            variant="h4"
                            component="h4"
                            className="view-titles"
                          >
                            Sales Order Report
                          </Index.Typography>
                          <Index.Typography
                            variant="h5"
                            component="h5"
                            className="view-subtitle"
                          >
                            (
                            {
                              reportType?.find(
                                (ele) => ele?.value == watch("reportType")
                              )?.title
                            }
                            )
                          </Index.Typography>
                        </Index.Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Party
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Principal/Broker
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Order No.
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Date
                      </TableCell>
                      {getSizeMaster
                        ?.sort((a, b) => a.type - b.type)
                        ?.map((row, index) => (
                          <TableCell
                            component="th"
                            scope="row"
                            className="table-cell-th"
                            key={row?.id}
                          >
                            {row?.size_name}
                          </TableCell>
                        ))}
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell-th"
                      >
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData?.map((row, index) => {
                      return (
                        <>
                          <TableRow key={`${row?.orderNo}-${index}`}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                              colSpan="100%"
                            >
                              {row?.ProductName}
                            </TableCell>
                          </TableRow>
                          <TableRow key={index}>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.party}
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.principalParty || row?.broker}
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.orderNo}
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {moment(row?.orderDate).format("DD-MM-YYYY")}
                            </TableCell>
                            {getSizeMaster
                              ?.sort((a, b) => a.type - b.type)
                              ?.map((sizeRow, index) => (
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="table-cell-td"
                                  key={row?.size_code}
                                >
                                  <Index.Box className="table-data-text">
                                    {
                                      row?.productData?.find(
                                        (ele) =>
                                          ele?.sizeId == sizeRow?.size_code
                                      )?.dispatchRemainQty
                                    }
                                  </Index.Box>
                                </TableCell>
                              ))}
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              <b className="bold-text">
                                {row?.productData?.reduce(
                                  (accumulator, row) => {
                                    return accumulator + row?.dispatchRemainQty;
                                  },
                                  0
                                )}
                              </b>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    {reportData?.length ? (
                      <>
                        <TableRow>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          ></TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          ></TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          ></TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <b className="bold-text">Grand Total</b>
                          </TableCell>

                          {getSizeMaster
                            ?.sort((a, b) => a.type - b.type)
                            ?.map((sizeRow, index) => (
                              <TableCell
                                component="td"
                                scope="row"
                                className="table-cell-td"
                                key={sizeRow?.size_code}
                              >
                                <Index.Box className="table-data-text">
                                  <b className="bold-text">
                                    {reportData?.reduce((sum, entry) => {
                                      entry.productData?.forEach((product) => {
                                        if (
                                          product?.sizeId == sizeRow?.size_code
                                        ) {
                                          sum += product.dispatchRemainQty || 0;
                                        }
                                      });

                                      return sum;
                                    }, 0)}
                                  </b>
                                </Index.Box>
                              </TableCell>
                            ))}
                          <TableCell
                            component="td"
                            scope="row"
                            className="table-cell-td"
                          >
                            <b className="bold-text">
                              {reportData?.reduce((sum, entry) => {
                                entry.productData?.forEach((product) => {
                                  sum += product.dispatchRemainQty || 0;
                                });

                                return sum;
                              }, 0)}
                            </b>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Index.Box>
        </Index.Box>
      );
    }
  };

  if (loading) {
    <LoadingSpinner></LoadingSpinner>;
  } else {
    return (
      <div>
        <Index.Box className="main_content">
          <Index.Box className="web_main_content">
            <Index.Box className="over_text_scroll">
              <Index.Box className="admin-dashboard-list-row">
                <Index.Box
                  className="report-main-box report-main-box-set"
                  ref={filterRef}
                >
                  <form
                    className="form-content"
                    onSubmit={handleSubmit(submit)}
                  >
                    <Index.Box className="input-box stock-report-input-box webview-input-box">
                      <Index.Box className="form-group date-of-purchase">
                        <Index.FormHelperText className="label_control">
                          From date
                        </Index.FormHelperText>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <Controller
                              control={control}
                              {...register("fromDate")}
                              render={({ field: { onChange, value } }) => (
                                <MobileDatePicker
                                  disableFuture={true}
                                  className="custom_form_control"
                                  minDate={dayjs(selectedYearStart)}
                                  maxDate={dayjs(selectedYearEnd)}
                                  format="DD/MM/YYYY"
                                  value={value}
                                  onChange={(newValue) => {
                                    setValue("fromDate", newValue);
                                    setValue("toDate", "");
                                  }}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      error: Boolean(errors.fromDate),
                                      // error : true
                                    },
                                  }}
                                  error={Boolean(errors.fromDate)}
                                />
                              )}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-box stock-report-input-box webview-input-box">
                      <Index.Box className="form-group date-of-purchase">
                        <Index.FormHelperText className="label_control">
                          To date
                        </Index.FormHelperText>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <Controller
                              control={control}
                              {...register("toDate")}
                              render={({ field: { onChange, value } }) => (
                                <MobileDatePicker
                                  className="custom_form_control"
                                  disableFuture={true}
                                  minDate={dayjs(selectedYearStart)}
                                  maxDate={dayjs(selectedYearEnd)}
                                  format="DD/MM/YYYY"
                                  value={value}
                                  onChange={(newValue) => {
                                    setValue("toDate", newValue);
                                  }}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      error: Boolean(errors.toDate),
                                      // error : true
                                    },
                                  }}
                                  error={Boolean(errors.toDate)}
                                />
                              )}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-box stock-report-input-box webview-input-box">
                      <Index.Box className="form-group">
                        <Index.FormHelperText className="label_control">
                          order no
                        </Index.FormHelperText>
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="custom_form_control"
                          value={watch("orderNo")}
                          {...register("orderNo")}
                          error={Boolean(errors.orderNo)}
                        />
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-box stock-report-input-box webview-input-box">
                      <Index.Box className="form-group">
                        <Index.FormHelperText className="label_control">
                          Principal Party
                        </Index.FormHelperText>
                        <Index.Box className="dropdown-box auto-drop-box">
                          <Controller
                            control={control}
                            {...register("principalParty", {
                              // required:"Princiapl party is required.",
                            })}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                className="custom_form_control"
                                value={
                                  value
                                    ? sundryDebitorsPrincipal.find(
                                        (o) => o.id === value
                                      )
                                    : value || null
                                }
                                options={sundryDebitorsPrincipal}
                                autoHighlight
                                onChange={(e, value) => {
                                  onChange(value?.id ? value?.id : "");
                                }}
                                getOptionLabel={(option) => option?.accountName}
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": {
                                        mr: 2,
                                        flexShrink: 0,
                                      },
                                    }}
                                    {...props}
                                  >
                                    {option?.accountName}
                                  </Index.Box>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    fullWidth
                                    error={Boolean(errors?.principalParty)}
                                    className="inner_custom_form_control"
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-box stock-report-input-box webview-input-box">
                      <Index.Box className="form-group">
                        <Index.FormHelperText className="label_control">
                          Party
                        </Index.FormHelperText>
                        <Index.Box className="dropdown-box auto-drop-box">
                          <Controller
                            control={control}
                            {...register("party")}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                className="custom_form_control"
                                options={sundryDebitors || []}
                                autoHighlight
                                onChange={(e, value) => {
                                  onChange(value?.id ? value?.id : "");
                                }}
                                getOptionLabel={(option) => option?.accountName}
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": {
                                        mr: 2,
                                        flexShrink: 0,
                                      },
                                    }}
                                    {...props}
                                  >
                                    {option?.accountName}
                                  </Index.Box>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    fullWidth
                                    error={Boolean(errors?.party)}
                                    className="inner_custom_form_control"
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-box stock-report-input-box webview-input-box">
                      <Index.Box className="form-group">
                        <Index.FormHelperText className="label_control">
                          Broker
                        </Index.FormHelperText>
                        <Index.Box className="dropdown-box auto-drop-box">
                          <Controller
                            control={control}
                            {...register("broker")}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                className="custom_form_control"
                                value={
                                  value
                                    ? brokers.find((o) => o.id === value)
                                    : value || null
                                }
                                options={brokers || []}
                                autoHighlight
                                onChange={(e, value) => {
                                  onChange(value?.id ? value?.id : "");
                                }}
                                getOptionLabel={(option) => option?.accountName}
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": {
                                        mr: 2,
                                        flexShrink: 0,
                                      },
                                    }}
                                    {...props}
                                  >
                                    {option?.accountName}
                                  </Index.Box>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    fullWidth
                                    error={Boolean(errors?.broker)}
                                    className="inner_custom_form_control"
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-box stock-report-input-box webview-input-box">
                      <Index.Box className="form-group">
                        <Index.FormHelperText className="label_control">
                          Product
                        </Index.FormHelperText>
                        <Index.Box className="dropdown-box auto-drop-box">
                          <Controller
                            control={control}
                            {...register("product", {})}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                className="custom_form_control"
                                options={products || []}
                                autoHighlight
                                onChange={(e, value) => {
                                  onChange(
                                    value?.ProductCode ? value?.ProductCode : ""
                                  );
                                }}
                                getOptionLabel={(option) => option?.ProductName}
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": {
                                        mr: 2,
                                        flexShrink: 0,
                                      },
                                    }}
                                    {...props}
                                  >
                                    {option?.ProductName}
                                  </Index.Box>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    fullWidth
                                    error={Boolean(errors?.product)}
                                    className="inner_custom_form_control"
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-box stock-report-input-box webview-input-box">
                      <Index.Box className="form-group">
                        <Index.FormHelperText className="label_control">
                          Color
                        </Index.FormHelperText>
                        <Index.Box className="dropdown-box auto-drop-box">
                          <Controller
                            control={control}
                            {...register("color", {})}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                className="custom_form_control"
                                options={colors || []}
                                autoHighlight
                                onChange={(e, value) => {
                                  onChange(
                                    value?.color_code ? value?.color_code : ""
                                  );
                                }}
                                getOptionLabel={(option) => option?.color_name}
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": {
                                        mr: 2,
                                        flexShrink: 0,
                                      },
                                    }}
                                    {...props}
                                  >
                                    {option?.color_name}
                                  </Index.Box>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    fullWidth
                                    error={Boolean(errors?.color)}
                                    className="inner_custom_form_control"
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-box stock-report-input-box webview-input-box">
                      <Index.Box className="form-group">
                        <Index.FormHelperText className="label_control">
                          Report Type <span className="requried-star">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="dropdown-box auto-drop-box">
                          <Controller
                            control={control}
                            {...register("reportType", {
                              required: "Report type is required.",
                            })}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                className="custom_form_control"
                                options={reportType || []}
                                autoHighlight
                                onChange={(e, value) => {
                                  onChange(value?.value ? value?.value : "");
                                  setReportData([]);
                                }}
                                getOptionLabel={(option) => option?.title}
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": {
                                        mr: 2,
                                        flexShrink: 0,
                                      },
                                    }}
                                    {...props}
                                  >
                                    {option?.title}
                                  </Index.Box>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    fullWidth
                                    error={Boolean(errors?.reportType)}
                                    className="inner_custom_form_control"
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="sales-order-submit-flex">
                      <Index.Box className="checkbox-content-flex">
                        <Index.Typography className="checkbox-lable">
                          Only Pending
                        </Index.Typography>
                        <Index.Box className="checkbox-main">
                          <Controller
                            control={control}
                            {...register("reportType", {
                              required: "Report type is required.",
                            })}
                            render={({ field: { onChange, value } }) => (
                              <input
                                type="checkbox"
                                onChange={() =>
                                  setValue("onlyPending", !watch("onlyPending"))
                                }
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-flex-end ">
                        <Index.Box className="btn_linear webview-button-container">
                          <Index.Button
                            variant=""
                            className="linear-btn-main webview-submit-btn"
                            type="submit"
                          >
                            {isLoading ? (
                              <Index.Box className="webview-spinner-main">
                                <Index.CircularProgress className="webview-spinner"></Index.CircularProgress>
                              </Index.Box>
                            ) : (
                              "Submit"
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                  {/* {reportData?.length ? (
                    <Index.Box className="btn-flex-end">
                      <Index.Box className="secondary-btn-main">
                        <ContainerIndex.LinearButton
                          btnLabel="Print"
                          className="linear-btn-main"
                          onClick={() => handlePrint()}
                        ></ContainerIndex.LinearButton>
                      </Index.Box>{" "}
                      <ContainerIndex.LinearButton
                        btnLabel="Excel"
                        className="linear-btn-main"
                        onClick={() => exportToExcel()}
                      ></ContainerIndex.LinearButton>
                    </Index.Box>
                  ) : (
                    <></>
                  )} */}
                  {reportContent(reportData)}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    );
  }
}
