import React from "react";
import Index from "./Index";
import DynamicTitle from "./seo/DynamicTitle";

const PageNotFound = () => {
  return (
    <>
      <DynamicTitle title="404 Page not found" />
      <Index.Box className="login_main forgot_main">
        <Index.Box className="login_pd_container">
          <Index.Box className="cus_row align_center_text">
            <Index.Box className="left_bg_login">
              <Index.Box className="bg_logins">
                <Index.Box className="login_details_main">
                  <img src={Index.Png.Logo} className="logo_login" />
                  <Index.Box className="login_title_content">
                    <Index.Typography
                      variant="h5"
                      component="h5"
                      className="main_title_login"
                    >
                      404 page not found
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="main_content_login"
                    >
                      We are sorry but the page you are looking for does not
                      exist.
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="right_content_login">
              <Index.Box className="content_details_login">
                <Index.Box className="title_login_details">
                  <Index.Typography
                    variant="h4"
                    component="h4"
                    className="back_welcome_title"
                  >
                    404 page not found
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="signin_account_text"
                  >
                    We are sorry but the page you are looking for does not
                    exist.
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="return_to_login_text forgot_link_end">
                  <Index.Link to={"/"} className="forgot_link_text">
                    Back to Login
                  </Index.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default PageNotFound;
