import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import DynamicTitle from "../../../../component/seo/DynamicTitle";
import {
  deleteSalesVoucherEntryApiHandler,
  getSalesVoucherEntryListApiHandler,
} from "../../../../services/AdminIndex";
import { toast } from "react-toastify";
import moment from "moment";

function paginate(array, rowsPerPage, page_number) {
  return array.slice(
    (page_number - 1) * rowsPerPage,
    page_number * rowsPerPage
  );
}

export default function PurchaseVoucherEntryList() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { token, permissions, selectedYearStart, selectedYearEnd } =
    Index.useSelector((state) => {
      return state.ERPAdminReducer;
    });

  const [page, setPage] = useState(1);

  const [purchaseVoucherEntryData, setPurchaseVoucherEntryData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getPurchaseVoucherEntryData = (id) => {
    dispatch({ type: "LOADER_START" });
    getSalesVoucherEntryListApiHandler(
      token,
      0,
      1,
      "purchaseVoucher",
      selectedYearStart,
      selectedYearEnd
    ).then((response) => {
      if (response.status === 201 || response.status === 200) {
        setPurchaseVoucherEntryData(response?.data);

        dispatch({ type: "LOADER_END" });
      } else {
        dispatch({ type: "ADMIN_ERROR_HANDLER", payload: response });
      }
    });
  };

  useEffect(() => {
    getPurchaseVoucherEntryData();
  }, [selectedYearStart]);

  useEffect(() => {
    setSearchedData(purchaseVoucherEntryData);
    setFilterData(purchaseVoucherEntryData?.reverse());
  }, [purchaseVoucherEntryData]);

  // ************** Search functions *****************
  const handleSearchData = (search) => {
    if (!search) return setSearchedData(purchaseVoucherEntryData);
    // 👇️ take parameter passed from Child component
    const result = searchedData.filter((item) => {
      return (
        (item?.voucherNo || "").toLowerCase().includes(search?.toLowerCase()) ||
        (item?.partyName || "").toLowerCase().includes(search?.toLowerCase()) ||
        (item?.salesAccountName || "")
          .toString()
          .toLowerCase()
          .includes(search?.toLowerCase())
      );
    });
    setSearchedData(result);
    setPage(1);
  };

  const rowsPerPage = 10;
  useEffect(() => {
    setFilterData(searchedData);
  }, [page, searchedData]);
  const deletePurchaseVoucherEntry = (id) => {
    dispatch({ type: "LOADER_START" });
    deleteSalesVoucherEntryApiHandler(token, id).then((response) => {
      if (response.status === 201 || response.status === 200) {
        toast.success("Purchase voucher entry deleted successfully");
        dispatch({ type: "LOADER_END" });
        getPurchaseVoucherEntryData();
      } else {
        dispatch({ type: "ADMIN_ERROR_HANDLER", payload: response });
      }
    });
  };

  // Delete Record function
  const deletehandler = async (id) => {
    Index.Swal.fire({
      title: "",
      text: "Are you sure you want to delete this record ?",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deletePurchaseVoucherEntry(id);
      }
    });
  };
  // #endregion

  return (
    <>
      <DynamicTitle title="ERP Purchase Voucher" />
      <Index.Box className="main_content">
        <Index.Box className="title_top_fix">
          <Index.Box className="flex_justify">
            <Index.Typography variant="h4" component="h4">
              Purchase Voucher Entry
            </Index.Typography>
            <Index.Box className="search_list_main">
              <Index.Box className="search_input form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="custom_form_control search_control"
                  placeholder="Search"
                  onChange={(e) => handleSearchData(e.target.value)}
                />
                <Index.Box className="search_btn">
                  <Index.Button className="btn_search">
                    <img
                      src={Index.Png.Search}
                      className="search"
                      alt="Search"
                    ></img>
                  </Index.Button>
                </Index.Box>
              </Index.Box>

              {permissions?.includes("voucher_entry_purchase_add") && (
                <Index.LinearButton
                  btnLabel="Add"
                  onClick={() => {
                    navigate("/dashboard/generate-purchase-voucher");
                  }}
                  className="linear-btn-main add_btn"
                />
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="web_main_content">
          <Index.Box className="over_text_scroll">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="report-main-box">
                      <Index.Box className="page-table-main sales-entry-list-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container padding-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="3%"
                                >
                                  Sr.
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="8%"
                                >
                                  Voucher No.
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="8%"
                                >
                                  Date
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="8%"
                                >
                                  Party
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="8%"
                                >
                                  Amount
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="8%"
                                >
                                  Purchase Acc.
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="8%"
                                >
                                  Action
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>

                            <Index.TableBody className="table-body">
                              {filterData?.length > 0 ? (
                                paginate(filterData, rowsPerPage, page)?.map(
                                  (row, index) => (
                                    <Index.TableRow key={row?.id}>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {((page - 1) * rowsPerPage) + index + 1}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.voucherNo}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {moment(row?.voucherDate).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.partyName}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.totalAmount}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.salesAccountName}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >

                                        <Index.Box className="userdata-btn-flex">
                                          {/* Edit Icon */}

                                          <Index.Tooltip
                                            title="Invoice 2"
                                            placement="top"
                                          >
                                            <Index.Link
                                              className="table-btns table-edit-btn"
                                              disableRipple
                                              to={`/dashboard/purches-print/${row?.id}`}
                                            >
                                              <img
                                                src={Index.Svg.whiteeye}
                                                className="table-icons"
                                              ></img>
                                            </Index.Link>
                                          </Index.Tooltip>

                                          {permissions?.includes(
                                            "voucher_entry_purchase_edit"
                                          ) ? (
                                            <Index.Tooltip
                                              title="Edit"
                                              placement="top"
                                            >
                                              <Index.Link
                                                className="table-btns table-edit-btn"
                                                disableRipple
                                                to={`/dashboard/edit-purchase-voucher/${row?.id}`}
                                              >
                                                <img
                                                  src={Index.Svg.whiteedit}
                                                  className="table-icons"
                                                ></img>
                                              </Index.Link>
                                            </Index.Tooltip>
                                          ) : (
                                            <Index.Box
                                              className="table-btns table-edit-btn table-disabled-btn"
                                              disableRipple
                                            >
                                              <img
                                                src={Index.Svg.whiteedit}
                                                className="table-icons"
                                              ></img>
                                            </Index.Box>
                                          )}

                                          {/* Delete Icon */}
                                          {permissions?.includes(
                                            "voucher_entry_purchase_delete"
                                          ) ? (
                                            <Index.Tooltip
                                              title="Delete"
                                              placement="top"
                                            >
                                              <Index.Link
                                                className="table-btns table-eye-btn"
                                                disableRipple
                                                onClick={() => {
                                                  deletehandler(row?.id);
                                                }}
                                              >
                                                <img
                                                  src={Index.Svg.whitetrash}
                                                  className="table-icons"
                                                ></img>
                                              </Index.Link>
                                            </Index.Tooltip>
                                          ) : (
                                            <Index.Box
                                              className="table-btns table-edit-btn table-disabled-btn"
                                              disableRipple
                                            >
                                              <img
                                                src={Index.Svg.whitetrash}
                                                className="table-icons"
                                              ></img>
                                            </Index.Box>
                                          )}
                                        </Index.Box>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  )
                                )
                              ) : (
                                <Index.TableRow>
                                  <Index.TableCell
                                    className="no-record-found-text"
                                    colSpan={7}
                                    align="center"
                                  >
                                    No record found
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>

                        <Index.Box className="pagination-table-design">
                          <Index.Stack spacing={2} className="stack-pagination">
                            <Index.Pagination
                              count={Math.ceil(
                                filterData?.length / rowsPerPage
                              )}
                              page={page}
                              onChange={handleChangePage}
                              variant="outlined"
                              shape="rounded"
                              className="stack-pagination-list"
                            />
                          </Index.Stack>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
