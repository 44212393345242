import React from 'react'
import { styled } from '@mui/material/styles';
import Index from './Index';

const BorderLinearProgress = styled(Index.LinearProgress)(({ theme }) => ({
      height: 10,
      borderRadius: 5,
      [`&.${Index.linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
      },
      [`& .${Index.linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#3c0000' : '#308fe8',
      },
}));

export default function Spinner(props) {
      return (
            <Index.Box sx={{ position: 'relative' , display: 'flex' }}>
                  <Index.CircularProgress
                        variant="determinate"
                        sx={{
                              color: (theme) =>
                                    theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        }}
                        size={20}
                        thickness={4}
                        {...props}
                        value={100}
                  />
                  <Index.CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                              color: (theme) => (theme.palette.mode === 'light' ? '#c85803' : '#308fe8'),
                              animationDuration: '550ms',
                              position: 'absolute',
                              left: 0,
                              [`& .${Index.circularProgressClasses.circle}`]: {
                                    strokeLinecap: 'round',
                              },
                        }}
                        size={20}
                        thickness={4}
                        {...props}
                  />
            </Index.Box>

      )
}
