import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import QRCode from "qrcode";
import { useReactToPrint } from "react-to-print";

export default function QrBannerPage(props) {
  const {
    setOpen,
    selectedBarcode,
    existBarcodeData,
    productCode,
    products,
    setId,
    setMaster,
    colors,
  } = props;

  const [qrUrl, setQrUrl] = useState("");
  const [printData, setPrintData] = useState({});
  useEffect(() => {
    const filteredBarcode = existBarcodeData?.filter(
      (row) => row?.barcode == selectedBarcode
    );

    setPrintData(filteredBarcode?.[0]);
    if (filteredBarcode?.[0]?.barcode) {
      const data = {
        productId: products?.find((ele) => ele.productCode == productCode),
        colorId: colors?.find(
          (ele) => ele.color == filteredBarcode?.[0]?.colorId
        ),
        setId: setMaster?.find((ele) => ele.id == setId),
      };

      QRCode.toDataURL(
        `${productCode},${filteredBarcode?.[0]?.colorId},${setId}`,
        function (err, url) {
          if (url) {
            setQrUrl(url);
          }
        }
      );
    }
  }, []);
  const handleClose = () => {
    setOpen(false);
    window.close();
  };
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  return (
    <Index.Box className="">
      <Index.Box className="modal-header">
        <Index.Typography varient="h4" component="h4" className="modal-title">
          Print Barcode
        </Index.Typography>
        <Index.Button className="modal-close-btn" onClick={handleClose}>
          <img src={Index.Png.Cancel} className="cancel-img"></img>
        </Index.Button>
      </Index.Box>

      <Index.Box className="modal-content-main modal-scroll">
        <Index.Box className="cus-card-main-box" ref={printRef}>
          <Index.Box className="cus-card-main">
            <Index.Box class=" ">
              {" "}
              <Index.Typography
                variant="p"
                component="p"
                className="main-qr-banner-text banner-border-set"
              >
                ******************************
              </Index.Typography>
            </Index.Box>
            <Index.Box className="">
              <Index.Typography
                variant="p"
                component="p"
                className="main-qr-banner-text"
              >
                {/* FORTH JEANS */}
                {printData?.ProductName}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="">
              <Index.Typography
                variant="p"
                component="p"
                className="main-qr-banner-text-new"
              >
                Product By{" "}
                <span className="main-qr-banner-text-new">
                  {" "}
                  : KALYAN SILKS{" "}
                </span>
              </Index.Typography>
            </Index.Box>
            <Index.Box className="">
              <Index.Typography
                variant="p"
                component="p"
                className="main-qr-banner-inner"
              >
                MADE IN INDIA
              </Index.Typography>
            </Index.Box>
            <Index.Box className="">
              <Index.Typography
                variant="p"
                component="p"
                className="main-qr-banner-text-new customer-set"
              >
                Customer Care No{" "}
                <span className="main-qr-banner-text-new customer-set">
                  {" "}
                  : 9929986521{" "}
                </span>
              </Index.Typography>
            </Index.Box>
            <Index.Box class=" ">
              {" "}
              <Index.Typography
                variant="p"
                component="p"
                className="main-qr-banner-text banner-border-set"
              >
                ******************************
              </Index.Typography>
            </Index.Box>
            <Index.Box className="">
              {/* <Index.Typography
                      variant="p"
                      component="p"
                      className="main-qr-banner-text"
                    >
                  [ 36 ]
                    </Index.Typography> */}
            </Index.Box>

            <Index.Box className=" banner-box-top">
              <Index.Box className="main-box-banner">
                <Index.Box className="qr-banner-text">
                  <Index.Typography variant="p" component="p" className="">
                    Design No.
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="qr-banner-number">
                  <Index.Typography variant="p" component="p" className="">
                    {/* : 3968 */}: {printData?.id}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="main-box-banner">
                <Index.Box className="qr-banner-text">
                  <Index.Typography variant="p" component="p" className="">
                    Style
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="qr-banner-number">
                  <Index.Typography variant="p" component="p" className="">
                    : Trouser
                  </Index.Typography>
                </Index.Box>
              </Index.Box>

              <Index.Box className="main-box-banner">
                <Index.Box className="qr-banner-text">
                  <Index.Typography variant="p" component="p" className="">
                    SIZE
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="qr-banner-number">
                  <Index.Typography variant="p" component="p" className="">
                    {/* : 36/91 cms */}: {printData?.sizeName}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="main-box-banner">
                <Index.Box className="qr-banner-text">
                  <Index.Typography variant="p" component="p" className="">
                    Colour
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="qr-banner-number">
                  <Index.Typography variant="p" component="p" className="">
                    {/* : Z.BLACK */}: {printData?.color_name}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="main-box-banner">
                <Index.Box className="qr-banner-text">
                  <Index.Typography variant="p" component="p" className="">
                    FIT
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="qr-banner-number">
                  <Index.Typography variant="p" component="p" className="">
                    : SKINNY
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="main-box-banner">
                <Index.Box className="qr-banner-text">
                  <Index.Typography variant="p" component="p" className="">
                    Qty
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="qr-banner-number">
                  <Index.Typography variant="p" component="p" className="">
                    {/* : 1PC */}: {printData?.qty}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>

              <Index.Box className="main-box-banner">
                <Index.Box className="qr-banner-text">
                  <Index.Typography variant="p" component="p" className="">
                    Mfg Date
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="qr-banner-number">
                  <Index.Typography variant="p" component="p" className="">
                    : 05/2023
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="main-box-banner">
                <Index.Box className="qr-banner-text">
                  <Index.Typography variant="p" component="p" className="">
                    MRP
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="qr-banner-number">
                  <Index.Typography variant="p" component="p" className="">
                    {/* : RS1495.00{" "} */}: {`RS ${printData?.mrp}`}
                    <span className="banner-number-text">
                      {" "}
                      (use of All Taxes){" "}
                    </span>
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="main-box-banner">
                <Index.Box className="qr-banner-text">
                  <Index.Typography variant="p" component="p" className="">
                    Product
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="qr-banner-number">
                  <Index.Typography variant="p" component="p" className="">
                    {/* : Readymade Garments */}: {printData?.ProductName}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box class=" ">
              {" "}
              <Index.Typography
                variant="p"
                component="p"
                className="main-qr-banner-text banner-border-set"
              >
                ******************************
              </Index.Typography>
            </Index.Box>
            <Index.Box class=" MuiBox-root css-0">
              {" "}
              <Index.Typography
                variant="p"
                component="p"
                className="main-qr-banner-text"
              >
                Thank you for shopping!
              </Index.Typography>
            </Index.Box>
            <Index.Box className="banner-img-box">
              {/* <img src={Index.Png.bannerimg} className="banner-img" /> */}
              <img src={qrUrl} className="banner-img" />
            </Index.Box>
          </Index.Box>
          <Index.Box className="btn_right_align btn-space-between">
            <Index.Box className="btn-qty-box"> </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="modal-footer-main">
        <Index.Box className="modal-btn-flex">
          <Index.Box className="btn-qty-box"> </Index.Box>
          <Index.SecondaryButton
            btnLabel="Cancel"
            className="secondary_button cancel_btn"
            onClick={handleClose}
          ></Index.SecondaryButton>

          <Index.LinearButton
            // disabled={loading}
            btnLabel="Print"
            className="linear-btn-main savechange_btn"
            onClick={() => handlePrint()}
          ></Index.LinearButton>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
